import request from '@/utils/request'

// 国内项目列表
export function getGnxmList(data) {
  return request({
    url: '/New_list/gnxmList',
    method: 'get',
    params: data
  })
}
// 国外项目列表
export function getHwxmList(data) {
  return request({
    url: '/New_list/hwxmList',
    method: 'get',
    params: data
  })
}