import axios from 'axios'
import { Notification, Message } from 'element-ui'

import errorCode from '@/utils/errorCode'


axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'
// 创建axios实例
const service = axios.create({
  // axios中请求配置有baseURL选项，表示请求URL公共部分
  // baseURL: process.env.VUE_APP_BASE_API,
  // baseURL: process.env.VUE_APP_ASSET_ADMIN_HOST + process.env.VUE_APP_BASE_API,
  baseURL: 'http://www.sxsyjsjy.com/api',
  // // 超时
  timeout: 20000
})

// 添加请求拦截器
service.interceptors.request.use(config => {
  // 是否需要设置 token
  // const isToken = (config.headers || {}).isToken === false
  // if (getToken() && !isToken) {
  //   // config.headers['Authorization'] = 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhcHBJZCI6IiIsImxvZ2luRnJvbSI6Ik1BUktFVCIsInB1Ymxpc2hlcklkIjoiIiwiTXVsdGlMb2dpbiI6ZmFsc2UsImF1ZCI6IuWFg-WInSIsImV4cCI6MTY3MDE1MTA5MiwianRpIjoiOW90aXQxMDgwMDBjbDExbGJ2bm95ZzAyMDA2Y3d6MzkiLCJzdWIiOiLlhYPliJ0ifQ.hhWQYuRttTfvxTbAg671rt72vDxl08p5eTodt7mRN8Y'
  //   config.headers['Authorization'] = 'Bearer ' + getToken() // 让每个请求携带自定义token 请根据实际情况自行修改
  // }
  return config
}, error => {
  console.log(error)
  Promise.reject(error)
});

// 添加响应拦截器
service.interceptors.response.use(res => {
  // 未设置状态码则默认成功状态
  const code = res.data.code || 0;
  // 获取错误信息
  const message = errorCode[code] || res.data.msg || errorCode['default']
  if (code === -401) {

    // MessageBox.confirm(
    //   '登录状态已过期，您可以继续留在该页面，或者重新登录',
    //   '系统提示',
    //   {
    //     confirmButtonText: '重新登录',
    //     cancelButtonText: '取消',
    //     type: 'warning'
    //   }
    // ).then(() => {
    //   store.dispatch('FedLogOut').then(() => {
    //     // location.reload() // 为了重新实例化vue-router对象 避免bug
    //   })
    // })
  } else if (code === 500) {
    Message({
      message: message,
      type: 'error'
    })
    return Promise.reject(new Error(message))
  } else if (code === 400) {
    Message({
      message: message,
      type: 'warning'
    })
    return Promise.reject(new Error(message))
  } else if (code !== 1 && code !== 200) {
    Notification.error({
      title: message
    })
    return Promise.reject('error')
  } else {
    return res.data
  }
},
  error => {
    console.log('err' + error)
    Message({
      message: error.message,
      type: 'error',
      duration: 5 * 1000
    })
    return Promise.reject(error)
  }
)

export default service