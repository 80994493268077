<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
export default {
  name: 'App'
}
</script>

<style lang="scss">
#app {
  padding: 0px;
  margin: 0px;
}
body{
  padding: 0px;
  margin: 0px;
}
p{
  margin: 0px;
  padding: 0px;
}
.el-carousel__container{
	height: 280px !important;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover !important;
  }
}

.one-line-text {
	display: -webkit-box;
  /*! autoprefixer: off */
  -webkit-box-orient: vertical;
  /* autoprefixer: on */
	-webkit-line-clamp: 1;
	overflow: hidden;
}
.tow-line-text {
  -webkit-line-clamp: 2;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  /*! autoprefixer: off */
  -webkit-box-orient: vertical;
  /* autoprefixer: on */
}
.san-line-text {
  -webkit-line-clamp: 3;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  /*! autoprefixer: off */
  -webkit-box-orient: vertical;
  /* autoprefixer: on */
}
.four-line-text {
  -webkit-line-clamp: 4;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  /*! autoprefixer: off */
  -webkit-box-orient: vertical;
  /* autoprefixer: on */
}
</style>
