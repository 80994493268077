<template>
  <div>
    <headerH></headerH>
    <div
      style="
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      "
    >
      <div
        style="
          width: 65%;
          height: auto;
          flex-direction: column;
          margin-top: 1.015rem;
        "
      >
        <!-- <el-carousel :interval="5000" arrow="always" height="1.8rem">
              <el-carousel-item v-for="(item,index) in imgData" :key="index" >
                <img :src="item.image" style="width: 100%;" alt="">
              </el-carousel-item>
            </el-carousel> -->
        <el-carousel height="280px" :interval="5000">
          <el-carousel-item v-for="(item, index) in imgData" :key="index">
            <img :src="item.image" alt="img" />
          </el-carousel-item>
        </el-carousel>
        <div style="display: flex; flex-direction: row; margin-top: 60px">
          <div style="width: 3.2389rem; height: 1.52rem; overflow: hidden">
            <img
              :src="info.gszx[0].image"
              style="width: 100%; height: 100%; object-fit: cover"
              alt=""
            />
          </div>
          <div
            style="
              display: flex;
              flex-direction: column;
              width: 3.2389rem;
              margin-left: 0.1833rem;
              height: 1.52rem;
            "
          >
            <div
              style="
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                width: 3.2389rem;
                height: 0.175rem;
                background: #1a356f;
                align-items: center;
              "
            >
              <div
                style="
                  display: flex;
                  flex-direction: row;
                  width: 0.425rem;
                  height: 0.125rem;
                  background: #fff;
                  text-align: center;
                  line-height: 0.125rem;
                  margin-left: 0.025rem;
                  justify-content: center;
                "
              >
                公司资讯
              </div>
              <div
                style="
                  display: flex;
                  flex-direction: row;
                  align-items: center;
                  color: #ffffff;
                  font-size: 0.07rem;
                  margin-right: 0.0556rem;
                "
                @click="gongsizxBtn({})"
              >
                More
              </div>
            </div>
            <div style="display: flex; flex-direction: column; cursor: pointer">
              <div
                v-for="(item, index) in info.gszx"
                :key="index"
                @click="gongsizxBtn(item)"
                v-show="index < 6"
                style="
                  display: flex;
                  flex-direction: row;
                  width: 100%;
                  height: 0.222rem;
                  align-items: center;
                  justify-content: space-between;
                  border-bottom: #d2d2d2 dashed 1px;
                "
              >
                <div
                  style="
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                  "
                >
                  <div
                    style="
                      width: 0.0333rem;
                      height: 0.0333rem;
                      background: #a7a7a7;
                    "
                  ></div>
                  <div
                    style="
                      margin-left: 0.0444rem;
                      font-size: 0.0889rem;
                      -webkit-line-clamp: 1;
                      display: -webkit-box;
                      -webkit-box-orient: vertical;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      width: 2rem;
                    "
                  >
                    {{ item.name }}
                  </div>
                </div>
                <div style="font-size: 0.0833rem; color: #9f9f9f">
                  {{ item.time }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          style="
            display: flex;
            flex-direction: row-reverse;
            margin-top: 0.3125rem;
          "
        >
          <div
            style="
              display: flex;
              flex-direction: column;
              width: 3.2389rem;
              margin-left: 0.1833rem;
              height: 1.52rem;
            "
          >
            <div
              style="
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                width: 3.2389rem;
                height: 0.175rem;
                background: #1a356f;
                align-items: center;
              "
            >
              <div
                style="
                  display: flex;
                  flex-direction: row;
                  width: 0.425rem;
                  height: 0.125rem;
                  background: #fff;
                  text-align: center;
                  line-height: 0.125rem;
                  margin-left: 0.025rem;
                  justify-content: center;
                "
              >
                项目动态
              </div>
              <div
                style="
                  display: flex;
                  flex-direction: row;
                  align-items: center;
                  color: #ffffff;
                  font-size: 0.07rem;
                  margin-right: 0.0556rem;
                "
                @click="xiangmudtBtn({})"
              >
                More
              </div>
            </div>
            <div style="display: flex; flex-direction: column; cursor: pointer">
              <div
                v-for="(item, index) in info.xmdt"
                :key="index"
                @click="xiangmudtBtn(item)"
                v-show="index < 6"
                style="
                  display: flex;
                  flex-direction: row;
                  width: 100%;
                  height: 0.222rem;
                  align-items: center;
                  justify-content: space-between;
                  border-bottom: #d2d2d2 dashed 1px;
                "
              >
                <div
                  style="
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                  "
                >
                  <div
                    style="
                      width: 0.0333rem;
                      height: 0.0333rem;
                      background: #a7a7a7;
                    "
                  ></div>
                  <div
                    style="
                      margin-left: 0.0444rem;
                      font-size: 0.0889rem;
                      -webkit-line-clamp: 1;
                      display: -webkit-box;
                      -webkit-box-orient: vertical;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      width: 2rem;
                    "
                  >
                    {{ item.name }}
                  </div>
                </div>
                <div style="font-size: 0.0833rem; color: #9f9f9f">
                  {{ item.time }}
                </div>
              </div>
            </div>
          </div>
          <div
            style="
              display: flex;
              flex-direction: column;
              width: 3.0389rem;
              margin-left: 0.1833rem;
              height: 1.52rem;
            "
          >
            <div
              style="
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                width: 3.0389rem;
                height: 0.175rem;
                background: #1a356f;
                align-items: center;
              "
            >
              <div
                style="
                  display: flex;
                  flex-direction: row;
                  width: 0.425rem;
                  height: 0.125rem;
                  background: #fff;
                  text-align: center;
                  line-height: 0.125rem;
                  margin-left: 0.025rem;
                  justify-content: center;
                "
              >
                文化建设
              </div>
              <div
                style="
                  display: flex;
                  flex-direction: row;
                  align-items: center;
                  color: #ffffff;
                  font-size: 0.07rem;
                  margin-right: 0.0556rem;
                "
                @click="wenhuajstz({})"
              >
                More
              </div>
            </div>
            <div style="display: flex; flex-direction: column; cursor: pointer">
              <div
                v-for="(item, index) in info.whjs"
                :key="index"
                v-show="index < 6"
                @click="wenhuajstz(item)"
                style="
                  display: flex;
                  flex-direction: row;
                  width: 100%;
                  height: 0.222rem;
                  align-items: center;
                  justify-content: space-between;
                  border-bottom: #d2d2d2 dashed 1px;
                "
              >
                <div
                  style="
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                  "
                >
                  <div
                    style="
                      width: 0.0333rem;
                      height: 0.0333rem;
                      background: #a7a7a7;
                    "
                  ></div>
                  <div
                    style="
                      margin-left: 0.0444rem;
                      font-size: 0.0889rem;
                      -webkit-line-clamp: 1;
                      display: -webkit-box;
                      -webkit-box-orient: vertical;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      width: 2rem;
                    "
                  >
                    {{ item.name }}
                  </div>
                </div>
                <div style="font-size: 0.0833rem; color: #9f9f9f">
                  {{ item.time }}
                </div>
              </div>
            </div>
          </div>
          <!-- <div style="display: flex;flex-direction: column;width: 65%;margin-right: .3542rem;">
                  <div class="rigth" style="display: flex;flex-direction: row;justify-content: space-between;">
                    <div style="display: flex;flex-direction: row;">
                      <div class="line"></div>
                      <div class="titleText">项目动态</div>
                    </div>
                    <div style="display: flex;flex-direction: row;align-items: center;" @click="xiangmudtBtn()">
                      <div style="width: .0208rem;height: .0208rem;background: #B7B7B7;"></div>
                      <div style="width: .0208rem;height: .0208rem;background: #B7B7B7;margin-left: .0313rem;"></div>
                      <div style="width: .0208rem;height: .0208rem;background: #B7B7B7;margin-left: .0313rem;"></div>
                    </div>
                  </div>
                  <div class="" style="display: flex;flex-direction: column;cursor: pointer;">
                    <div style="display: flex;flex-direction: row;align-items: center;justify-content: space-between;margin-top: .1042rem;border-bottom: #d2d2d2 solid 1px;line-height: .181rem;"
                    @mouseover="handleMouseOverA($event,item,index)"
                    @click="jump('0',item)" v-for="(item,index) in info.xmdt" :key="index">
                      <div class="rigthA" style="display: flex;flex-direction: column;" @click="titleXiangqONE()" v-if="indexShowA == index">
                        <div style="margin-top: .099rem;color: #202020;font-size: .1042rem;width: 4rem;-webkit-line-clamp: 1;display: -webkit-box;-webkit-box-orient: vertical;overflow: hidden;text-overflow: ellipsis;">{{item.name}}</div>
                        <div style="margin-top: .059rem;font-size: .0833rem;color: #6B6B6B;line-height: .1146rem;width: 4rem;-webkit-line-clamp: 2;display: -webkit-box;-webkit-box-orient: vertical;overflow: hidden;text-overflow: ellipsis">
                          {{item.intro}}
                        </div>
                      </div>
                      <div style="display: flex;flex-direction: row;justify-content: center;align-items: center;" v-if="indexShowA != index">
                        <div style="width: .0313rem;height: .0313rem;background: #A7A7A7;"></div>
                        <div style="margin-left: .0417rem;color: #333333;font-size: .0833rem;width: 3.3042rem;-webkit-line-clamp: 1;display: -webkit-box;-webkit-box-orient: vertical;overflow: hidden;text-overflow: ellipsis" v-html="item.info_content"></div>
                      </div>
                      <div style="font-size: .0833rem;color: #9F9F9F;" v-if="indexShowA != index">{{ item.time }}</div>
                    </div>
                  </div>
                </div> -->
        </div>
        <!-- <div style="display: flex;flex-direction: row;margin-top: .3125rem;">
                <div style="width: 31%;height: 2.2604rem;overflow: hidden;">
                  <img :src="info.whjs[0].image" style="width: auto;height: 100%;" alt="">
                </div>
                <div style="display: flex;flex-direction: column;width: 65%;margin-left: .3542rem;">
                  <div class="rigth" style="display: flex;flex-direction: row;justify-content: space-between;">
                    <div style="display: flex;flex-direction: row;">
                      <div class="line"></div>
                      <div class="titleText">文化建设</div>
                    </div>
                    <div style="display: flex;flex-direction: row;align-items: center;" @click="wenhuajstz()">
                      <div style="width: .0208rem;height: .0208rem;background: #B7B7B7;"></div>
                      <div style="width: .0208rem;height: .0208rem;background: #B7B7B7;margin-left: .0313rem;"></div>
                      <div style="width: .0208rem;height: .0208rem;background: #B7B7B7;margin-left: .0313rem;"></div>
                    </div>
                  </div>

                  <div class="" style="display: flex;flex-direction: column;cursor: pointer;">
                    <div style="display: flex;flex-direction: row;align-items: center;justify-content: space-between;margin-top: .1042rem;border-bottom: #d2d2d2 solid 1px;line-height: .181rem;"
                    @mouseover="handleMouseOverB($event,item,index)"
                    @click="jumpA('1',item)" v-for="(item,index) in info.whjs" :key="index">
                      <div class="rigthA" style="display: flex;flex-direction: column;" @click="titleXiangqTWO()" v-if="indexShowB == index">
                        <div style="margin-top: .099rem;color: #202020;font-size: .1042rem;width: 4rem;-webkit-line-clamp: 1;display: -webkit-box;-webkit-box-orient: vertical;overflow: hidden;text-overflow: ellipsis;">{{item.name}}</div>
                        <div style="margin-top: .059rem;font-size: .0833rem;color: #6B6B6B;line-height: .1146rem;width: 4rem;-webkit-line-clamp: 2;display: -webkit-box;-webkit-box-orient: vertical;overflow: hidden;text-overflow: ellipsis" >
                          {{item.intro}}
                        </div>
                      </div>
                      <div style="display: flex;flex-direction: row;justify-content: center;align-items: center;" v-if="indexShowB != index">
                        <div style="width: .0313rem;height: .0313rem;background: #A7A7A7;"></div>
                        <div style="margin-left: .0417rem;color: #333333;font-size: .0833rem;width: 3.3042rem;-webkit-line-clamp: 1;display: -webkit-box;-webkit-box-orient: vertical;overflow: hidden;text-overflow: ellipsis" v-html="item.info_content"></div>
                      </div>
                      <div style="font-size: .0833rem;color: #9F9F9F;" v-if="indexShowB != index">{{ item.time }}</div>
                    </div>
                  </div>
                </div>
              </div> -->
      </div>
      <div
        style="
          width: 65%;
          height: 1.1531rem;
          margin-top: 0.3125rem;
          display: flex;
          flex-direction: column;
        "
      >
        <div
          style="
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            width: 6.5011rem;
            height: 0.175rem;
            background: #1a356f;
            align-items: center;
          "
        >
          <div
            style="
              display: flex;
              flex-direction: row;
              width: 0.425rem;
              height: 0.125rem;
              background: #fff;
              text-align: center;
              line-height: 0.125rem;
              margin-left: 0.025rem;
              justify-content: center;
            "
          >
            工程巡礼
          </div>
          <div
            style="
              display: flex;
              flex-direction: row;
              align-items: center;
              color: #ffffff;
              font-size: 0.07rem;
              margin-right: 0.0556rem;
            "
            @click="gongsizxBtn()"
          ></div>
        </div>
        <!-- <div
          style="
            width: 100%;
            display: flex;
            flex-direction: row;
            height: 0.7552rem;
            justify-content: center;
            align-items: center;
            margin-top: 0.1042rem;
          "
        >
          <vue-seamless-scroll
            :data="listData"
            :class-option="classOption"
            class="warp"
            :direction="2"
          >
            <ul class="ul-item">
              <li
                class="li-item"
                v-for="(item, index) in listData"
                :key="index"
              >
                <el-image
                  :src="item"
                  :z-index="999999"
                  :preview-src-list="listData"
                >
                </el-image>
              </li>
            </ul>
          </vue-seamless-scroll>
        </div> -->
        <div style="width: 100%; margin-top: 0.1042rem;" v-if="listData.length">
          <image-list :listData="listData"></image-list>
        </div>
      </div>
    </div>
    <bottomB></bottomB>
  </div>
</template>

<script>
import vueSeamlessScroll from 'vue-seamless-scroll'
import headerH from '../components/HelloWorld.vue'
import bottomB from '../components/bottom.vue'
import axios from 'axios'
import Vue from 'vue'
import imageList from '@/components/ImageList.vue'

export default {
  data() {
    return {
      imgData: [],
      listData: [],
      classOption: {
        limitMoveNum: 2,
        direction: 2
      },
      info: {
        gszx: [{ name: '', image: '' }],
        xmdt: [{ name: '', image: '' }],
        whjs: [{ name: '', image: '' }]
      },
      indexShow: 0,
      indexShowA: 0,
      indexShowB: 0
    }
  },
  computed: {
    optionHover() {
      return {
        step: 1000, // 数值越大速度滚动越快
        limitMoveNum: this.dataList.length, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 2, // 0向下 1向上 2向左 3向右
        openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000 // 单步运动停止的时间(默认值1000ms)
      }
    }
  },
  components: {
    headerH,
    vueSeamlessScroll,
    bottomB,
    imageList
  },
  created() {
    //轮播
    this.getLunbo()
    //this.getPeiz()
    //内容
    this.getcontet()
  },
  methods: {
    handleMouseOverA(e, item, index) {
      this.indexShowA = index
    },

    handleMouseOver(e, item, index) {
      this.indexShow = index
    },
    handleMouseOverB(e, item, index) {
      this.indexShowB = index
    },
    wenhuajstz(item) {
      this.$router.push({
        path: '/Qywenhau',
        query: {
          index: 4,
          id: item.news_ntype_id,
          getID: item.id
        }
      })
    },
    gongsizxBtn(item) {
      this.$router.push({
        path: '/newDongtai',
        query: {
          idS: item.news_ntype_id,
          getID:item.id
        }
      })
    },
    getLunbo() {
      axios({
        method: 'post',
        // url: 'http://www.sxsyjsjy.com/api/index/banner?type=index',
        url:
          process.env.VUE_APP_ASSET_ADMIN_HOST + '/api/index/banner?type=index'
      }).then((res) => {
        this.imgData = res.data.data
      })
    },
    xiangmudtBtn(item) {
      this.$router.push({
        path: '/newDongtai',
        query: {
          index: 2,
          id: item.news_ntype_id,
          getID: item.id
        }
      })
    },
    //跳转1
    jump(index, item) {
      this.$router.push({
        path: '/newDongtai',
        query: {
          id: index,
          getID: item.id
        }
      })
    },
    titleXiangq() {
      this.$router.push({
        path: '/newDongtai',
        query: {
          id: 0,
          getID: this.info.gszx[0].id
        }
      })
    },
    titleXiangqONE() {
      this.$router.push({
        path: '/newDongtai',
        query: {
          id: 1,
          getID: this.info.xmdt[0].id
        }
      })
    },
    titleXiangqTWO() {
      this.$router.push({
        path: '/Qywenhau',
        query: {
          id: 1,
          getID: this.info.whjs[0].id
        }
      })
    },
    jumpA(index, item) {
      this.$router.push({
        path: '/Qywenhau',
        query: {
          id: index,
          getID: item.id
        }
      })
    },
    getPeiz() {
      axios({
        method: 'post',
        url: process.env.VUE_APP_ASSET_ADMIN_HOST + '/api/index/config_share'
      }).then((res) => {
        //this.imgData = res.data.data
      })
    },
    timestampToTime(timestamp) {
      // 时间戳为10位需*1000，时间戳为13位不需乘1000
      var date = new Date(timestamp * 1000)
      var Y = date.getFullYear() + '-'
      var M =
        (date.getMonth() + 1 < 10
          ? '0' + (date.getMonth() + 1)
          : date.getMonth() + 1) + '-'
      var D =
        (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' '
      var h = date.getHours() + ':'
      var m = date.getMinutes() + ':'
      var s = date.getSeconds()
      return Y + M + D
    },
    getcontet() {
      axios({
        method: 'post',
        url: process.env.VUE_APP_ASSET_ADMIN_HOST + '/api/index/index'
      }).then((res) => {
        this.listData = res.data.data.gcxl.map((x) => x.image)
        console.log('listData', this.listData)
        this.info.gszx = res.data.data.gszx
        // if(res.data.data.gszx.length > 6 && res.data.data.gszx.length != 0){ this.info.gszx = res.data.data.gszx.slice(1,6)}else{
        // }
        this.info.whjs = res.data.data.whjs
        // if(res.data.data.whjs.length > 6 && res.data.data.whjs.length != 0){ this.info.whjs = res.data.data.whjs.slice(1,6)}else{
        // }
        this.info.xmdt = res.data.data.xmdt
        // if(res.data.data.xmdt.length > 6 && res.data.data.xmdt.length != 0){ this.info.xmdt = res.data.data.xmdt.slice(1,6)}else{
        // }
        this.info.gszx.forEach((item) => {
          item.time = this.timestampToTime(item.time)
          item.info_content = item.info_content
        })
        this.info.whjs.forEach((item) => {
          item.time = this.timestampToTime(item.time)
        })
        this.info.xmdt.forEach((item) => {
          item.time = this.timestampToTime(item.time)
        })
        axios({
          method: 'post',
          url:
            process.env.VUE_APP_ASSET_ADMIN_HOST +
            '/api/index/banner?type=gszx_index'
        }).then((res) => {
          Vue.set(this.info.gszx[0], 'image', res.data.data[0].image)
        })

        axios({
          method: 'post',
          url:
            process.env.VUE_APP_ASSET_ADMIN_HOST +
            '/api/index/banner?type=xmdt_index'
        }).then((res) => {
          Vue.set(this.info.xmdt[0], 'image', res.data.data[0].image)
        })

        axios({
          method: 'post',
          url:
            process.env.VUE_APP_ASSET_ADMIN_HOST +
            '/api/index/banner?type=whjs_index'
        }).then((res) => {
          Vue.set(this.info.whjs[0], 'image', res.data.data[0].image)
        })
      })
    }
  }
}
</script>

<style lang="scss">
.aaaaaaaaa :hover {
  color: #0d4da2 !important;
}
.warp {
  width: 6.4792rem;
  height: 0.7552rem;
  margin: 0 auto;
  overflow: hidden;
  ul {
    list-style: none;
    padding: 0;
    margin: 0 auto;
    &.ul-item {
      display: flex;
      .li-item {
        width: 1.1198rem;
        height: 0.7552rem;
        margin-right: 0.1615rem;
        line-height: 0.7552rem;
        background-color: #999;
        color: #fff;
        text-align: center;
        font-size: 30px;
      }
    }
  }
}

.line {
  width: 0.0208rem;
  height: 0.1354rem;
  background: #0d4da2;
  margin-top: 7px;
}
.titleText {
  color: #0d4da2;
  font-size: 0.1458rem;
  font-weight: 600;
  margin-left: 0.0365rem;
}
.rigth {
  width: 100%;
  height: 0.2604rem;
  border-bottom: 1px solid #d2d2d2;
}
.rigthA {
  width: 100%;
  height: 0.6771rem;
}
.cont_bj {
  width: 75%;
  height: 100%;
  display: flex;
  flex-direction: row;
  color: #fff;
}
.cont_one {
  // width: .7552rem;
  height: 0.3125rem;
  text-align: center;
  line-height: 0.3125rem;
  // margin-left: .2083rem;
}
.actives {
  background: #003973;
}
.el-carousel__item h3 {
  color: #475669;
  font-size: 0.0938rem;
  opacity: 0.75;
  line-height: 1.5625rem;
  margin: 0;
}
.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}
.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}
</style>
