<template>
  <div>
      <headerH @btnSele="btnClick($event)"></headerH>
      <!--<div style="width: 100%;height: .4375rem;background: rgba(0, 0, 0, 0.6);position: fixed;z-index: 99999;margin-top: 1.015rem;display: flex;flex-direction: row;align-items: center;">
          <div style="display: flex;flex-direction: row;margin-left:2.524rem;">
              <div v-for="(item,ind) in listA" style="display: flex;flex-direction: row;" :key="ind" @click="tabBtn(item,ind)">
                  <div class="textX" :class="indexA == ind ?'aaa':''" style="margin-left: .1979rem;" @click="indexA = 0">{{item.name}}</div>
                  <div style="width: .0052rem;height: .125rem;background: #FFFFFF;margin-left: .1979rem;"></div>
              </div>
          </div>
      </div>-->
      <div style="width: 100%;display: flex;flex-direction: column;justify-content: center;align-items: center;">
        <div style="width: 65%;height: auto;flex-direction: column;margin-top: 1.015rem;">
          <el-carousel :interval="5000" arrow="always">
            <el-carousel-item v-for="(item,ind) in imgData" :key="ind"  >
                <img :src="item.image" style="width: 100%;" alt="">
              </el-carousel-item>
          </el-carousel>
          <div style="width: 100%;display: flex;flex-direction: row;margin-top: .2604rem;">
              <div style="width: 1.0938rem;display: flex;flex-direction: column;">
                  <div v-for="(item,index) in listA" :key="index"
                  @click="seleBtn(item,index)"
                  :class="id == item.id ?'acacac':''" style="width: 99%;height: .3594rem;text-align: center;line-height: .3594rem;text-align: center;display: flex;flex-direction: row;align-items: center;justify-content: center;color: #003973;border: 1px solid #E2E2E2;">
                      <!-- <div style="width: .0625rem;height: .0104rem;background: #CACACA;margin-right: .0521rem;"></div> -->
					  <div style="width: .03rem;height: .03rem;background: #CACACA;margin-right: .0521rem; border-radius: 50%;"></div>
                      <div style="font-size: .09rem;margin-left: .0521rem;">{{item.name}}</div>
                  </div>
              </div>
              <div style="width: 5rem;display: flex;flex-direction: column;margin-left: .1563rem;">
                  <div style="display: flex;flex-direction: row;align-items: center;">
                      <div style="width: .0156rem;height: .0729rem;background: #003973;margin-top: .0156rem;"></div>
                      <div style="font-size: .0833rem;margin-left: .0521rem;display: flex;flex-direction: row;">
                            <div>当前位置：国内项目 > </div>
                            <div style="color: #003973;">{{listA[indexA].name}}</div>
                        </div>
                  </div>
                  <div style="width: 5rem;height: .0208rem;background: #003973;margin-top: .1042rem;"></div>
                  <div style="margin-top: .1563rem;width: 5rem;height: auto;padding-bottom: .5208rem;" >
                    <!-- <div v-if="infoData.info_content" v-html="infoData.info_content"></div> -->
					<div v-for="(item, index) in listData" :key="index" class="list-line">
						<div class="line-num">{{ (index + 1) + (queryList.page - 1 ) * queryList.limit }}</div>
						<div class="line-name one-line-text">{{ item.name }}</div>
					</div>
					<div v-show="!listData.length">暂无数据</div>
                  </div>
				  <div style="width: 100%;display: flex;flex-direction: row;justify-content: center;align-items: center;">
				      <el-pagination
				          background
				          prev-text="上一页"
				          next-text="下一页"
				          layout="prev, pager, next"
				          :page-size.sync="queryList.limit"
				          :current-page.sync="queryList.page"
				          @size-change="getList"
				          @current-change="getList"
				          :total="total">
				      </el-pagination>
				  </div>
              </div>
          </div>
        </div>
      </div>
      <bottomB></bottomB>
  </div>
</template>

<script>
import headerH from '../components/HelloWorld.vue'
import bottomB from '../components/bottom.vue'
import axios from 'axios'
import Vue from 'vue'
import { getGnxmList } from '@/api/index'
export default {
  data () {
    return {
      indexA:0,
      listA:[{name:''},{name:''},{name:''},{name:''}],
      infoData:{},
      imgData:[],
	  id: 0,
	  queryList:{
	      page: 1, // 默认页码
	      limit: 22, // 每页条数
	  },
	  total: 0,
	  listData: []
    }
  },
  components: {
      headerH,
      bottomB,
  },
  created(){
      // if(this.$route.query.index == 0){this.indexA = 0}
      // if(this.$route.query.index == 2){this.indexA = 1}
      // if(this.$route.query.index == 4){this.indexA = 2}
      // if(this.$route.query.index == 6){this.indexA = 3}
      this.gettabs()
      this.lunbo()
  },
  methods:{
	  getList() {
        this.queryList.type_id = this.id
        getGnxmList(this.queryList).then((res) => {
            console.log(res)
			this.listData = res.data.data
			this.total = res.data.total
        })
	  },
        btnClick(e){
			this.id = e.id
			this.getList()
            // let num = 0
            // if(e.indexaAA == 0){this.indexA = 0}
            // if(e.indexaAA == 2){this.indexA = 1}
            // if(e.indexaAA == 4){this.indexA = 2}
            // if(e.indexaAA == 6){this.indexA = 3}
            // axios({
            //     method: 'post',
            //     url: `http://www.sxsyjsjy.com/api/New_list/gnxmList?type_id=${e.id}`,
            // }).then(res => {
            //     this.infoData = res.data.data.data[0]
            // })
        },
      lunbo(){
            axios({
                method: 'post',
                url: process.env.VUE_APP_ASSET_ADMIN_HOST + '/api/index/banner?type=index',
            }).then(res => {
                this.imgData = res.data.data
            })
      },
      seleBtn(item,index){
          this.queryList.page = 1
          this.indexA = index
		  this.id = item.id
		  this.getList()
          // axios({
          //     method: 'post',
          //     url: `http://www.sxsyjsjy.com/api/New_list/gnxmList?type_id=${item.id}`,
          // }).then(res => {
          //     this.infoData = res.data.data.data[0]
          // })
      },
      gettabs(){
          axios({
              method: 'post',
              url: process.env.VUE_APP_ASSET_ADMIN_HOST + '/api/New_type_list/gnxmTypeList',
          }).then(res => {
              this.listA = res.data.data
			  if (this.id == 0) {
				  this.id = this.listA[0].id
				  this.getList()
			  }
              // axios({
              //     method: 'post',
              //     url: `http://www.sxsyjsjy.com/api/New_list/gnxmList?type_id=${this.listA[0].id}`,
              // }).then(res => {
              //     this.infoData = res.data.data.data[0]
              // })
          })
          
      },
      tabBtn(item,index){
          this.indexA = index
          axios({
              method: 'post',
              url: `http://www.sxsyjsjy.com/api/New_list/gnxmList?type_id=${item.id}`,
          }).then(res => {
              this.infoData = res.data.data.data[0]
          })
      },
  }
}
</script>

<style lang="scss" scoped>
      .acacac{
          background: #003973;
          color: #fff !important;
      }
    //   .el-carousel__container{
    //       height: 2.3438rem !important;
    //   }
      .textX{
          color: #D6D6D6;
          font-size: .0833rem;
      }
      .aaa{
          color: #fff;
      }
	.list-line {
		display: flex;
		align-items: center;
		margin-bottom: 20px;
		
		.line-num {
			width: 50px;
			height: 50px;
			background: #003973;
			line-height: 50px;
			text-align: center;
			color: #FFF;
			margin-right: 10px;
		}
		.line-name {
			flex: 1;
			padding: 0 10px;
			color: #333;
			line-height: 50px;
			cursor: pointer;
            font-size: 18px;
		}
		&:nth-child(2n) .line-name {
			border: 1px solid #267acb;
		}
		&:nth-child(2n+1) .line-name {
			// border: 1px solid #267acb;
			background: #267acb;
			color: #FFF;
		}
	}

        .el-pagination.is-background .el-pager li:not(.disabled).active{
            background-color: #003973 !important;
        }
</style>
