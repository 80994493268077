<template>
    <div class="bbb">
        <headerH @sousuo="sousuoBtn"></headerH>
        <div style="width: 100%;display: flex;flex-direction: column;justify-content: center;align-items: center;">
          <div style="width: 65%;height: auto;flex-direction: column;margin-top: 1.015rem;">
            <el-carousel :interval="5000" arrow="always">
              <el-carousel-item v-for="(item,ind) in imgData" :key="ind"  >
                <img :src="item.image" style="width: 100%;height: 100%;" alt="">
              </el-carousel-item>
            </el-carousel>
            <div style="width: 100%;display: flex;flex-direction: column;margin-top: .2604rem;height: auto;">
                <div style="font-size: .1458rem;color: #2D2D2D;">搜索结果</div>
                <div style="margin-top: .0833rem;display: flex;flex-direction: column;cursor: pointer;background: #FFF;padding-bottom: .1146rem;"
                v-if="dataList.length > 0"
                @click="jump(item)"
                 v-for=" (item,index) in dataList" :key="index">
                    <div style="margin-top: .1146rem;margin-left: .1146rem;font-size: .1042rem;color: #373737;">{{item.name}}</div>
                    <div style="margin-top: .0417rem;color: #6E6C6C;font-size: .0833rem;margin-left: .1146rem;width: 95%;" v-html="item.intro"></div>
                    <div style="display: flex;flex-direction: row;margin-top: .1042rem;margin-left: .1146rem;">
                        <div style="display: flex;flex-direction: row;align-items: center;justify-content: center;">
                            <img src="../image/shijian.png" style="width: .1042rem;height: .1042rem;" alt="">
                            <div style="font-size: .0833rem;color: #6E6C6C;margin-left: .0104rem;">{{item.time}}</div>
                        </div>
                        <div style="display: flex;flex-direction: row;align-items: center;justify-content: center;margin-left: .1563rem;">
                            <img src="../image/liulan.png" style="width: .1198rem;height: .0833rem;" alt="">
                            <div style="font-size: .0833rem;color: #6E6C6C;margin-left: .0104rem;">{{item.page_view}}次浏览</div>
                        </div>
                    </div>
                </div>
                <div v-if="dataList.length <= 0">
                  <div style="margin-top: .1042rem;font-size: .082rem;">暂无搜索数据</div>
                </div>
                <div style="width: 100%;display: flex;flex-direction: row;justify-content: center;align-items: center;margin-top: .3646rem;">
                    <el-pagination
                        background
                        prev-text="上一页"
                        next-text="下一页"
                        layout="prev, pager, next"
                        :page-size="page.pagesize"
                        :current-page="page.page"
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        :total="page.total">
                    </el-pagination>
                </div>
            </div>
          </div>
        </div>
        <bottomB></bottomB>
    </div>
  </template>
  <script>
  var map = {};
  import headerH from '../components/HelloWorld.vue'
  import bottomB from '../components/bottom.vue'
  import axios from 'axios'
import Vue from 'vue'
  export default {
    name: 'index',
    data () {
      return {
        indexA:0,
        listA:["联系我们"],
        imgData:[],
        page:{
          page:1,
          pagesize:5,
          total:0
        },
        sousuo:'',
        dataList:[]
      }
    },
    components: {
        headerH,
        bottomB,
    },
    created(){
      this.lunbo()
    },
    mounted(){
        axios({
            method: 'post',
            url: `http://www.sxsyjsjy.com/api/New_list/xwdtList?name=${this.$route.params.search}&page=${this.page.page}&limit=${this.page.pagesize}`,
        }).then(res => {
          console.log(res.data.data,'shuj')
          this.dataList = res.data.data.data
          this.dataList.forEach(item => {
            item.time = this.timestampToTime(item.time)
          })
          this.page.total = res.data.data.total
        })
    },
    methods:{
      // 页容量改变
      handleSizeChange(size) {
          this.page.pagesize = size
          // 页容量回到第一页
          this.page.page = 1
          axios({
            method: 'post',
            url: `http://www.sxsyjsjy.com/api/New_list/xwdtList?name=${this.sousuo}&page=${this.page.page}&limit=${this.page.pagesize}`,
          }).then(res => {
            console.log(res.data.data,'shuj')
            this.dataList = res.data.data.data
            this.dataList.forEach(item => {
              item.time = this.timestampToTime(item.time)
            })
            this.page.total = res.data.data.total
          })
      },
      // 页码改变
      handleCurrentChange(page) {
          this.page.page = page
          axios({
            method: 'post',
            url: `http://www.sxsyjsjy.com/api/New_list/xwdtList?name=${this.sousuo}&page=${this.page.page}&limit=${this.page.pagesize}`,
          }).then(res => {
            console.log(res.data.data,'shuj')
            this.dataList = res.data.data.data
            this.dataList.forEach(item => {
              item.time = this.timestampToTime(item.time)
            })
            this.page.total = res.data.data.total
          })
      },
      timestampToTime(timestamp) {
        // 时间戳为10位需*1000，时间戳为13位不需乘1000
        var date = new Date(timestamp * 1000);
        var Y = date.getFullYear() + "-";
        var M =
          (date.getMonth() + 1 < 10
            ? "0" + (date.getMonth() + 1)
            : date.getMonth() + 1) + "-";
        var D = (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + " ";
        var h = date.getHours() + ":";
        var m = date.getMinutes() + ":";
        var s = date.getSeconds();
        return Y + M + D ;
      },
      jump(item){
        this.$router.push({
          path: '/newDongtai',
          query: {
            idS: item.news_ntype_id,
            getID:item.id
          }
        })
      },
      sousuoBtn(e){
        this.sousuo = e
        axios({
            method: 'post',
            url: `http://www.sxsyjsjy.com/api/New_list/xwdtList?name=${e}&page=${this.page.page}&limit=${this.page.pagesize}`,
        }).then(res => {
          console.log(res.data.data,'shuj')
          this.dataList = res.data.data.data
          this.dataList.forEach(item => {
            item.time = this.timestampToTime(item.time)
          })
          this.page.total = res.data.data.total
        })
      },
      lunbo(){
            axios({
                method: 'post',
                url: process.env.VUE_APP_ASSET_ADMIN_HOST + '/api/index/banner?type=lxwm',
            }).then(res => {
                this.imgData = res.data.data
            })
      },
      seleBtn(item,index){
          this.indexA = index
      },
    }
  }
  </script>
  
  <style lang="scss">
        .bbb{
          background: #F9F9F9;
        }
        .mapContainer {
          width: 100%;
          height: 100%;
        }
        .acacac{
            background: #003973;
            color: #fff !important;
        }
        // .el-carousel__container{
        //     height: 2.3438rem !important;
        // }
        .textX{
            color: #D6D6D6;
            font-size: .0833rem;
        }
        .aaa{
            color: #fff;
        }
  </style>
  