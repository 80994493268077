<template>
    <div>
        <div class="bottom" style="margin-top: .3646rem;color: #FFF;">
          <div style="display: flex;flex-direction: row;">
            <div>电话：{{infoData.dianhua}}</div>
            <div style="margin-left: .1111rem;">地址：{{infoData.dizhi}}</div>
          </div>
          <div style="display: flex;flex-direction: row;margin-top: .0556rem;">
            <div>版权所有：山西省冶金设计院有限公司</div>
            <div style="margin-left: .1111rem;" @click="beianhao">{{infoData.beian}}</div>
            <!-- <a style="margin-left: .1111rem; color: #FFF;" target="_blank" href="https://beian.miit.gov.cn/#/Integrated/index">
              备案号：{{ infoData.beian }}
            </a> -->

            <div @click="tiaozhuanbtn()" style="margin-left: .1111rem; cursor: pointer;">技术支持：科辉荣盛</div>
          </div>

            <!-- <div style="width: 60%;height: 1.8229rem;display: flex;flex-direction: column;">
                <div style="width: 70%;display: flex;flex-direction: row;justify-content: space-around;align-items: center;margin-top: .1563rem;">
                    <div class="textB">
                      <router-link style="color: #fff;text-decoration: none;" to="/index">首页</router-link>
                    </div>
                    <div class="textB">
                      <router-link style="color: #fff;text-decoration: none;" to="/briefIntroduction">企业简介</router-link>
                    </div>
                    <div class="textB">
                      <router-link style="color: #fff;text-decoration: none;" to="/newDongtai">新闻动态</router-link>
                    </div>
                    <div class="textB">
                      <router-link style="color: #fff;text-decoration: none;" to="/DomesticProjects">国内项目</router-link>
                    </div>
                    <div class="textB">
                      <router-link style="color: #fff;text-decoration: none;" to="/OverseasProjects">海外项目</router-link>
                    </div>
                    <div class="textB">
                      <router-link style="color: #fff;text-decoration: none;" to="/Qywenhau">企业文化</router-link>
                    </div>
                    <div class="textB">
                      <router-link style="color: #fff;text-decoration: none;" to="/lianxiwm">联系我们</router-link>
                    </div>
                </div>
                <div style="margin-top: 45px;display: flex;flex-direction: column;margin-left: .1783rem;">
                    <div class="textC">山西省冶金设计院有限公司</div>
                    <div class="textC">地址：{{infoData.dizhi}}</div>
                    <div class="textC">电话：{{infoData.dianhua}}</div>
                    <div class="textC">邮编：{{infoData.youbian}}</div>
                    <div class="textC">网址：{{infoData.wangzhi}}</div>
                    <div class="textC">邮箱：{{infoData.youxiang}}</div>
                </div>
            </div> -->
        </div>
        <!-- <div style="width: 100%;height: .2083rem;background: #003973;display: flex;flex-direction: row;justify-content: center;">
            <div style="width: 40%;display: flex;flex-direction: row;justify-content: space-around;align-items: center;font-size: .0938rem;color: #fff;">
                <div>备案号：{{infoData.beian}}</div>
                <div @click="tiaozhuanbtn()" style="cursor: pointer;">技术支持：科辉荣盛</div>
            </div>
        </div> -->
    </div>
  </template>
  
  <script>
  import axios from 'axios'

  export default {
    name: 'HelloWorld',
    data () {
      return {
        list:["首页","企业简介","新闻动态","国内项目","海外项目","企业文化","联系我们"],
        infoData:{}
      }
    },
    created(){
      this.getData()
    },
    methods:{
      getData(){
        axios({
            method: 'get',
          url: process.env.VUE_APP_ASSET_ADMIN_HOST + '/api/index/config_share',
        }).then(res => {
          this.infoData = res.data.data
        })
      },
      tiaozhuanbtn(){
        window.open("http://www.bjkhrs.com/","_blank");
      },
      beianhao() {
        window.open("https://beian.miit.gov.cn/#/Integrated/index","_blank");
      }
    }
  }
  </script>
  
  <style scoped>
  .textB{
    font-size: .0833rem;
    color: #fff;
  }
    .textC{
        font-size: .0938rem;
        color: #FFFFFF;
        margin-top: .0625rem;
    }
    .bottom{
        width: 100%;
        height: .5rem;
        background: #ACACAC;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: .09rem;
    }
  </style>
  