<template>
    <div>
        <headerH @btnSele="btnClick($event)"></headerH>
        <!--<div
            style="width: 100%;height: .4375rem;background: rgba(0, 0, 0, 0.6);position: fixed;z-index: 99999;margin-top: 1.015rem;display: flex;flex-direction: row;align-items: center;">
            <div style="display: flex;flex-direction: row;margin-left: 2.524rem;">
                <div v-for="(item, ind) in listA" style="display: flex;flex-direction: row;" :key="ind"
                    @click="tabBtn(item, ind)">
                    <div class="textX" :class="indexA == ind ? 'aaa' : ''" style="margin-left: .1979rem;" @click="indexA = 0">
                        {{ item.name }}</div>
                    <div style="width: .0052rem;height: .125rem;background: #FFFFFF;margin-left: .1979rem;"></div>
                </div>
            </div>
        </div>-->
        <div style="width: 100%;display: flex;flex-direction: column;justify-content: center;align-items: center;">
            <div style="width: 65%;height: auto;flex-direction: column;margin-top: 1.015rem;">
                <el-carousel :interval="5000" arrow="always">
                    <el-carousel-item v-for="(item, ind) in imgData" :key="ind">
                        <img :src="item.image" style="width: 100%;" alt="">
                    </el-carousel-item>
                </el-carousel>
                <div style="width: 100%;display: flex;flex-direction: row;margin-top: .2604rem;">
                    <div style="width: 1.0938rem;display: flex;flex-direction: column;">
                        <div v-for="(item, index) in listA" :key="index" @click="seleBtn(item, index)"
                            :class="indexA == index ? 'acacac' : ''"
                            style="width: 99%;height: .3594rem;text-align: center;line-height: .3594rem;text-align: center;display: flex;flex-direction: row;align-items: center;justify-content: center;color: #003973;border: 1px solid #E2E2E2;">
                            <!-- <div style="width: .0625rem;height: .0104rem;background: #CACACA;margin-right: .0521rem;"></div> -->
							<div style="width: .03rem;height: .03rem;background: #CACACA;margin-right: .0521rem; border-radius: 50%;"></div>
                            <div style="font-size: .09rem;margin-left: .0521rem;">{{ item.name }}</div>
                        </div>
                    </div>
                    <div style="width: 5rem;display: flex;flex-direction: column;margin-left: .1563rem;">
                        <div style="display: flex;flex-direction: row;align-items: center;">
                            <div style="width: .0156rem;height: .0729rem;background: #003973;margin-top: .0156rem;"></div>
                            <div style="font-size: .0833rem;margin-left: .0521rem;display: flex;flex-direction: row;">
                                <div>当前位置：企业文化 > </div>
                                <div @click="detailsShow = false" style="cursor: pointer;color: #003973;"> {{listA[indexA].name}}</div>
                                <div v-if="detailsShow == true"> > 详情</div>
                            </div>
                        </div>
                        <div style="width: 5rem;height: .0208rem;background: #003973;margin-top: .1042rem;"></div>
                        <!--文化建设-->
                        <div style="margin-top: .1563rem;width: 5rem;height: auto;padding-bottom: .5208rem;"
                            v-if="indexA == 2">
                            <div style="width: 5rem;padding-bottom: .3208rem;" v-if="detailsShow == false">
                                <div style="display: flex;flex-direction: column;">
                                    <div class="rigthC"
                                        style="display: flex;flex-direction: column;margin-top: .1563rem;height: .6719rem;border-bottom: solid 1px#F0F0F0;cursor: pointer;"
                                        @click="details(item,index)"
                                        v-for="(item, index) in dataList" :key="index">
                                        <div
                                            style="display: flex;flex-direction: row;align-items: center;justify-content: space-between;">
                                            <div
                                                style="display: flex;flex-direction: row;justify-content: center;align-items: center;">
                                                <div
                                                    class="acTIvv"
                                                    style="">
                                                    {{item.name}}
                                                </div>
                                            </div>
                                            <div style="font-size: .0833rem;color: #9F9F9F;">{{item.time}}</div>
                                        </div>
                                        <div
                                         style="margin-top: .1563rem;font-size: .0833rem;color: #474747 !important;margin-left: 0.0417rem;text-indent: .1563rem;width: 4.4042rem;-webkit-line-clamp: 2;display: -webkit-box;-webkit-box-orient: vertical;overflow: hidden;text-overflow: ellipsis;">
                                         {{item.intro}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-if="detailsShow == true">
                                <div style="display: flex;flex-direction: column;margin-top: .3125rem;">
                                    <div style="width: 100%;display: flex;flex-direction: row;justify-content: center;font-size: .1042rem;color: #1E1E1E;">{{detaInfo.name}}</div>
                                    <div style="width: 100%;display: flex;flex-direction: row;justify-content: center;margin-top: .1563rem;">
                                        <div style="width: 75%;display: flex;flex-direction: row;justify-content: space-around;color: #969696;font-size: .0833rem;">
                                            <div>作者：{{detaInfo.author}}</div>
                                            <div>时间：{{timestampToTime(detaInfo.time)}}</div>
                                            <div>浏览量：{{detaInfo.page_view}}</div>
                                        </div>
                                    </div>
                                    <div style="margin-top: .1563rem;" v-html="detaInfo.info_content"></div>
                                </div>
                            </div>
                            <div style="width: 100%;display: flex;flex-direction: row;justify-content: center;align-items: center;" v-if="detailsShow == false">
                                <el-pagination
                                    background
                                    prev-text="上一页"
                                    next-text="下一页"
                                    layout="prev, pager, next"
                                    :page-size="page.pagesize"
                                    :current-page="page.page"
                                    @size-change="handleSizeChangeAAA"
                                    @current-change="handleCurrentChangeAAA"
                                    :total="page.total">
                                </el-pagination>
                            </div>
                        </div>
                        <!--企业精神-->
                        <div style="width: 5rem;height: auto;display: flex;flex-direction: column;justify-content: center;align-items: center;cursor: pointer;"
                            v-if="indexA == 0 || indexA == 1">
                            <div v-html="dataList[0].info_content"></div>
                        </div>
                        <!--企业精神-->
                        <div style="width: 5rem;height: auto;display: flex;flex-direction: column;justify-content: center;align-items: center;cursor: pointer;"
                            v-if="indexA == 3">
                            <div style="width: 5rem;height: auto;padding-bottom: .3208rem;" v-if="detailsShow == false">
                                <div 
                                style="display: flex;flex-direction: column;width: 5rem;height: auto;margin-top: .2188rem;background: #FBFBFB;" 
                                @click="details(item,index)"
                                v-for="(item,ind) in dataList" :key="ind">
                                <div style="display: flex;flex-direction: row;" class="ceshi">
                                        <img :src="item.image" style="width: 1.5104rem;height: 1.0052rem;" alt="">
                                        <div style="margin-left: .1563rem;margin-top: .1563rem;display: flex;flex-direction: column;" >
                                            <div style="font-size: .0938rem;font-weight: 600;" >{{item.name}}</div>
                                            <div style="margin-top: .0625rem;font-size: .0833rem;color: #A6A6A6 !important;">{{item.time}}</div>
                                            <!-- <div v-html="item.info_content" style="margin-top: .1067rem;width: 3.2042rem;-webkit-line-clamp: 2;display: -webkit-box;-webkit-box-orient: vertical;overflow: hidden;text-overflow: ellipsis;font-size: .0833rem;color: #474747 !important;"> -->
                                            <div style="margin-top: .1067rem;width: 3.2042rem;-webkit-line-clamp: 2;display: -webkit-box;-webkit-box-orient: vertical;overflow: hidden;text-overflow: ellipsis;font-size: .0833rem;color: #474747 !important;">{{ item.intro }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-if="detailsShow == true">
                                <div style="display: flex;flex-direction: column;">
                                    <img :src="detaInfo.image" style="width: 100%;" alt="">
                                    <!-- <div style="margin-top: .1563rem;" v-html="detaInfo.info_content"></div> -->
                                    <div style="margin-top: .1563rem;">{{ item.intro }}</div>
                                </div>
                            </div>
                            <div style="width: 100%;display: flex;flex-direction: row;justify-content: center;align-items: center;" v-if="detailsShow == false">
                                <el-pagination
                                    background
                                    prev-text="上一页"
                                    next-text="下一页"
                                    layout="prev, pager, next"
                                    :page-size="page.pagesize"
                                    :current-page="page.page"
                                    @size-change="handleSizeChange"
                                    @current-change="handleCurrentChange"
                                    :total="page.total">
                                </el-pagination>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <bottomB></bottomB>
    </div>
</template>

<script>
import headerH from '../components/HelloWorld.vue'
import bottomB from '../components/bottom.vue'
import axios from 'axios'
import Vue from 'vue'
export default {
    name: 'index',
    data() {
        return {
            indexA: 0,
            listA: [{name:''},{name:''},{name:''},{name:''}],
            infoData: {},
            imgData: [],
            dataList:[],
            page:{
                page: 1, // 默认页码
                pagesize: 5, // 每页条数
                total: 100
            },
            detailsShow:false,
            detaInfo:{}
        }
    },
    components: {
        headerH,
        bottomB,
    },
    created() {
        console.log(this.$route.query.id)
        if(this.$route.query.id){
            this.indexA = this.$route.query.id
            this.detailsShow = true
            axios({
                method: 'post',
                url: `http://www.sxsyjsjy.com/api/New_info/qywhInfo?id=${this.$route.query.getID}`,
            }).then(res => {
                this.detaInfo = res.data.data.info
                this.detaInfo.info_content = this.formatRichText(this.detaInfo.info_content)
            })
        }
        if(this.$route.query.index){
            if(this.$route.query.index == 0){this.indexA = 0}
            if(this.$route.query.index == 2){this.indexA = 1}
            if(this.$route.query.index == 4){this.indexA = 2}
            if(this.$route.query.index == 6){this.indexA = 3}
        }
        this.gettabs()
        this.lunbo()
    },
    methods: {
        formatRichText(html) { //控制小程序中图片大小
            let newContent = ''
            if (typeof(html) == 'string') { //检测值为字符串才能进行replace操作
                newContent = html.replace(/<img[^>]*>/gi,
                function(match) {
                    match = match.replace(/style=""/gi, '').replace(/style=''/gi, '');
                    match = match.replace(/style="[^"]+"/gi, '').replace(/style='[^']+'/gi, '');
                    match = match.replace(/width="[^"]+"/gi, '').replace(/width='[^']+'/gi, '');
                    match = match.replace(/height="[^"]+"/gi, '').replace(/height='[^']+'/gi, '');
                    return match;
                });
                newContent = newContent.replace(/style="[^"]+"/gi,
                function(match) {
                    match = match.replace(/width:[^;]+;/gi, 'max-width:100%;').replace(/width:[^;]+;/gi, 'max-width:100%;');
                    return match;
                });
                newContent = newContent.replace(/<br[^>]*\/>/gi, '');
                newContent = newContent.replace(/\<img/gi, '<img style="max-width:100%;height:auto;display:inline-block;margin:10rpx auto;"');
                return newContent;
            }
        },
        btnClick(e){
            let num = 0
            if(e.indexaAA == 0){this.indexA = 0}
            if(e.indexaAA == 2){this.indexA = 1}
            if(e.indexaAA == 4){this.indexA = 2}
            if(e.indexaAA == 6){this.indexA = 3}
            axios({
                method: 'post',
                url: `http://www.sxsyjsjy.com/api/New_list/qywhList?type_id=${e.id}`,
            }).then(res => {
                this.infoData = res.data.data.data[0]
            })
        },
        timestampToTime(timestamp) {
            // 时间戳为10位需*1000，时间戳为13位不需乘1000
            var date = new Date(timestamp * 1000);
            var Y = date.getFullYear() + "-";
            var M =
            (date.getMonth() + 1 < 10
                ? "0" + (date.getMonth() + 1)
                : date.getMonth() + 1) + "-";
            var D = (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + " ";
            var h = date.getHours() + ":";
            var m = date.getMinutes() + ":";
            var s = date.getSeconds();
            return Y + M + D ;
        },
        handleSizeChangeAAA(size) {
            this.page.pagesize = size
            // 页容量回到第一页
            this.page.page = 1
            this.gettabs()
        },
        handleCurrentChangeAAA(page) {
            this.page.page = page
            this.gettabs()
        },
        // 页容量改变
        handleSizeChange(size) {
            this.page.pagesize = size
            // 页容量回到第一页
            this.page.page = 1
            this.gettabs()
        },
        // 页码改变
        handleCurrentChange(page) {
            this.page.page = page
            this.gettabs()
        },
         //详情
         details(item){
            
            this.detailsShow = true
            axios({
                method: 'post',
                url: `http://www.sxsyjsjy.com/api/New_info/qywhInfo?id=${item.id}`,
            }).then(res => {
                this.detaInfo = res.data.data.info
                this.detaInfo.info_content = this.formatRichText(this.detaInfo.info_content)
            })
        },
        lunbo() {
            axios({
                method: 'get',
                url: process.env.VUE_APP_ASSET_ADMIN_HOST + '/api/index/banner?type=qywh',
            }).then(res => {
                this.imgData = res.data.data
            })
        },
        seleBtn(item, index) {
            this.page.page = 1
            this.indexA = index
            this.detailsShow = false
            axios({
                method: 'get',
                url: `http://www.sxsyjsjy.com/api/New_list/qywhList?type_id=${item.id}&page=${this.page.page}&limit=${this.page.pagesize}`,
            }).then(res => {
                this.infoData = res.data.data.data[0]
                this.dataList = res.data.data.data
                this.dataList.forEach(item => {
                    item.time = this.timestampToTime(item.time)
                })
                this.page.total = res.data.data.total
            })
        },
        gettabs() {
            axios({
                method: 'get',
                url: process.env.VUE_APP_ASSET_ADMIN_HOST + '/api/New_type_list/qywhTypeList',
            }).then(res => {
                this.listA = res.data.data
                axios({
                    method: 'post',
                    url: `http://www.sxsyjsjy.com/api/New_list/qywhList?type_id=${this.listA[this.indexA].id}&page=${this.page.page}&limit=${this.page.pagesize}`,
                }).then(res => {
                    this.infoData = res.data.data.data[0]
                    this.dataList = res.data.data.data
                    this.dataList.forEach(item => {
                        item.time = this.timestampToTime(item.time)
                    })
                    this.page.total = res.data.data.total
                })
            })

        },
        tabBtn(item, index) {
            this.page.page = 1
            this.indexA = index
            this.detailsShow = false
            axios({
                method: 'get',
                url: `http://www.sxsyjsjy.com/api/New_list/qywhList?type_id=${item.id}&page=${this.page.page}&limit=${this.page.pagesize}`,
            }).then(res => {
                this.infoData = res.data.data.data[0]
                this.dataList = res.data.data.data
                this.dataList.forEach(item => {
                    item.time = this.timestampToTime(item.time)
                })
                this.page.total = res.data.data.total
            })
        },
    }
}
</script>

<style lang="scss">
.acTIvv{
    margin-left: .0417rem;font-weight: 600;font-size: .0833rem;width: 4.3042rem;-webkit-line-clamp: 1;display: -webkit-box;-webkit-box-orient: vertical;overflow: hidden;text-overflow: ellipsis
}
.rigthC:hover{
    color: #003973 !important;
}
.ceshi :hover{
    color: #003973 !important;

}
.acacac {
    background: #003973;
    color: #fff !important;
}

// .el-carousel__container {
//     height: 2.3438rem !important;
// }

.textX {
    color: #D6D6D6;
    font-size: .0833rem;
}

.aaa {
    color: #fff;
}</style>
