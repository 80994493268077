import Vue from 'vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import App from './App.vue'
import router from './router'
// import './style/common.scss'
import formatTime from '@/utils/filters.js'
import richImage from '@/utils/richImage.js'

import  "amfe-flexible";
import scroll from 'vue-seamless-scroll'
import hzqingVueTimeline from 'hzqing-vue-timeline'


Vue.prototype.formatTime = formatTime
Vue.prototype.richImage = richImage

Vue.config.productionTip = false


Vue.use(scroll)
Vue.use(scroll,{componentName:'scroll-seamless'})
Vue.use(hzqingVueTimeline)

Vue.use(ElementUI);

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
