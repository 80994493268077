<template>
    <div>
        <headerH></headerH>
        <div style="width: 100%;display: flex;flex-direction: column;justify-content: center;align-items: center;">
          <div style="width: 65%;height: auto;flex-direction: column;margin-top: 1.015rem;">
            <el-carousel :interval="5000" arrow="always">
              <el-carousel-item v-for="(item,ind) in imgData" :key="ind" >
                <img :src="item.image" style="width: 100%;" alt="">
              </el-carousel-item>
            </el-carousel>
            <div style="width: 100%;display: flex;flex-direction: row;margin-top: .2604rem;">
                <div style="width: 1.0938rem;height: 1.0938rem;display: flex;flex-direction: column;">
                    <div v-for="(item,index) in listA" :key="index"
                    @click="seleBtn(item,index)"
                    :class="indexA == index ?'acacac':''" style="width: 100%;height: .3594rem;text-align: center;line-height: .3594rem;text-align: center;display: flex;flex-direction: row;align-items: center;justify-content: center;color: #003973;">
                        <!-- <div style="width: .0625rem;height: .0104rem;background: #CACACA;margin-right: .0521rem;"></div> -->
						<div style="width: .03rem;height: .03rem;background: #CACACA;margin-right: .0521rem; border-radius: 50%;"></div>
                        <div style="font-size: .09rem;margin-left: .0521rem;">{{item}}</div>
                    </div>
                </div>
                <div style="width: 5rem;display: flex;flex-direction: column;margin-left: .1563rem;">
                    <div style="display: flex;flex-direction: row;align-items: center;">
                        <div style="width: .0156rem;height: .0729rem;background: #003973;margin-top: .0156rem;"></div>
                        <div style="font-size: .0833rem;margin-left: .0521rem;display: flex;flex-direction: row;">
                          <div>当前位置：</div>
                          <div style="color: #003973;">联系我们</div>
                        </div>
                    </div>
                    <div style="width: 5rem;height: .0208rem;background: #003973;margin-top: .1042rem;"></div>
                    <div style="margin-top: .1563rem;width: 5rem;height: 2rem;display: flex;flex-direction: row;" v-if="indexA == 0">
                      <div style="width: 2rem;height: 2rem;" @click="toMap">
                        <div class="mapContainer" id="map"></div>
                      </div>
                      <div style="width: 1.4167rem;height: 1.5625rem;margin-left: .8rem;margin-top: .3rem; display: flex;flex-direction: column;position: relative;">
                        <div style="font-size: .1146rem;color: #000;margin-top: .0729rem;font-weight: 600;">山西省冶金设计院有限公司</div>
                        <div style="width: 100%;height: .0104rem;background: #003973;position: absolute;top: .2783rem;"></div>
                        <div style="margin-top: .2708rem;color: #333333;font-size: .0833rem;">地址：{{infoData.dizhi}}</div>
                        <div style="margin-top: .1042rem;color: #333333;font-size: .0833rem;">邮编：{{infoData.youbian}}</div>
                        <div style="margin-top: .1042rem;color: #333333;font-size: .0833rem;">电话：{{infoData.dianhua}}</div>
                        <div style="margin-top: .1042rem;color: #333333;font-size: .0833rem;">网址：{{infoData.wangzhi}}</div>
                        <div style="margin-top: .1042rem;color: #333333;font-size: .0833rem;">E-mail：{{infoData.youxiang}}</div>
                      </div>
                    </div>
                </div>
            </div>
          </div>
        </div>
        <bottomB></bottomB>
    </div>
  </template>
  <script>
  var map = {};
  import headerH from '../components/HelloWorld.vue'
  import bottomB from '../components/bottom.vue'
  import axios from 'axios'
  export default {
    name: 'index',
    data () {
      return {
        indexA:0,
        listA:["联系我们"],
        imgData:[],
        infoData:{}
      }
    },
    components: {
        headerH,
        bottomB,
    },
    created(){
      this.lunbo()
      this.getData()
    },
    mounted(){
      this.initMap();
    },
    methods:{
      getData(){
        axios({
            method: 'get',
          url: process.env.VUE_APP_ASSET_ADMIN_HOST + '/api/index/config_share',
        }).then(res => {
          this.infoData = res.data.data
        })
      },
      lunbo(){
            axios({
                method: 'post',
                url: process.env.VUE_APP_ASSET_ADMIN_HOST + '/api/index/banner?type=index',
            }).then(res => {
                this.imgData = res.data.data
            })
      },
      seleBtn(item,index){
          this.indexA = index
      },
	  toMap() {
		  window.open('https://www.amap.com/search?query=%E5%B1%B1%E8%A5%BF%E7%9C%81%E5%A4%AA%E5%8E%9F%E5%B8%82%E8%BF%8E%E6%B3%BD%E5%A4%A7%E8%A1%97305%E5%8F%B7&city=140100&geoobj=111.780613%7C37.479151%7C113.758152%7C38.292027&zoom=10')
	  },
      //初始化地图
      initMap() {
        this.map = new window.AMap.Map("map", {
            resizeEnable: true, //是否监控地图容器尺寸变化
            center: [112.549383,37.860126],//地图中心点，按照自己要求设置即可
            zoom: 17,//地图缩放级别
          });
          //1.创建Marker对象
        let marker = new window.AMap.Marker({
            position: [112.549383,37.860126],//要展示marker的经度、纬度。数据格式就是数组里放入经纬度数据
            //icon: require("../../src/image/ddd.png"), //显示的图标
            icon: new AMap.Icon({
              size: new AMap.Size(25, 28),    // 图标尺寸
              image: require("../../src/image/ccc.png"),  // 自定义图像的url
              imageSize: new window.AMap.Size(25, 28)   // 根据所设置的大小拉伸或压缩图片
            }),
          });
          marker.setLabel({
            offset: new AMap.Pixel(-3, 3),
            content: `<div class="map-name">山西省冶金设计院有限公司</div>`,
            direction: 'bottom', //设置文本标注方位
          })
        this.map.add(marker); //添加marker
      }
    }
  }
  </script>
  
  <style lang="scss">
        .mapContainer {
          width: 100%;
          height: 100%;
        }
        .acacac{
            background: #003973;
            color: #fff !important;
        }
        // .el-carousel__container{
        //     height: 2.3438rem !important;
        // }
        .textX{
            color: #D6D6D6;
            font-size: .0833rem;
        }
        .aaa{
            color: #fff;
        }

        .amap-marker-label {
          border: none;
          background: rgba($color: #FFFFFF, $alpha: .3);
          color: red;
          font-weight: 700;
          font-size: 16px;
        }
  </style>
  