<template>
	<div style="position: fixed;width: 100%;z-index: 99999;">
		<div class="header">
			<div class="header_cont">
				<div style="display: flex;flex-direction: row;align-items: center;">
					<img src="../image/logo.png" @click="homeBtn()" style="width:100%;height: .3385rem;" alt="">
				</div>
				<div style="display: flex;flex-direction: row;align-items: center;">
					<div class="input" style="display: flex;flex-direction: row;height: .2344rem;align-items: center;">
						<input type="text" class="inputLeft"
							style="color: #09396F;border: none;outline: none;background: transparent;font-size: .0781rem;"
							v-model="search" placeholder="请输入关键词搜索">
						<div @click="sousuo()" class="divInput">
							<router-link :to="{ name: 'sousuo', params: { search: search }}">
								<img src="../image/sousuo.png" style="width: .1042rem;height: .1042rem;" alt="">
							</router-link>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div style="width: 100%;height: .3125rem;display: flex;flex-direction: row;justify-content: center;align-items: center;background: #267ACB;cursor: pointer;"
			@mouseleave="doSomething($event)">
			<div class="cont_bj" style="width: 65%;" @mouseleave="doSomething($event)">
				<div @mouseover="handleMouseOver('首页')" class="cont_bj1"><router-link to="/index" class="cont_one"
						:class="[indexActive == 0?'':'']" @click="jump(index)">首页</router-link></div>
				<div @mouseenter="handleMouseOver('企业简介')" class="cont_bj1"><router-link to="/briefIntroduction"
						class="cont_one" :class="[indexActive == 1?'':'']" @click="jump(index)">企业简介</router-link>
					<div v-show="text == '企业简介' && show && listA.length" class="list-col">
						<div v-for="(item,ind) in listA" :key="ind" @click="tabBtn(item,ind)">
							<div>{{ item.name }}</div>
						</div>
					</div>
				</div>
				<div @mouseenter="handleMouseOver('新闻动态')" class="cont_bj1"><router-link to="/newDongtai"
						class="cont_one" :class="[indexActive == 2?'':'']" @click="jump(index)">新闻动态</router-link>
					<div v-show="text == '新闻动态' && show && listA.length" class="list-col">
						<div v-for="(item,ind) in listA" :key="ind" @click="tabBtn(item,ind)">
							<div>{{ item.name }}</div>
						</div>
					</div>
				</div>
				<div @mouseenter="handleMouseOver('国内项目')" class="cont_bj1"><router-link to="/DomesticProjects"
						class="cont_one" :class="[indexActive == 3?'':'']" @click="jump(index)">国内项目</router-link>
					<div v-show="text == '国内项目' && show && listA.length" class="list-col">
						<div v-for="(item,ind) in listA" :key="ind" @click="tabBtn(item,ind)">
							<div>{{ item.name }}</div>
						</div>
					</div>
				</div>
				<div @mouseenter="handleMouseOver('海外项目')" class="cont_bj1"><router-link to="/OverseasProjects"
						class="cont_one" :class="[indexActive == 4?'':'']" @click="jump(index)">海外项目</router-link>
					<div v-show="text == '海外项目' && show && listA.length" class="list-col">
						<div v-for="(item,ind) in listA" :key="ind" @click="tabBtn(item,ind)">
							<div>{{ item.name }}</div>
						</div>
					</div>
				</div>
				<div @mouseenter="handleMouseOver('党建工作')" class="cont_bj1"><router-link to="/dangjian"
						class="cont_one" :class="[indexActive == 2?'':'']" @click="jump(index)">党建工作</router-link>
					<div v-show="text == '党建工作' && show && listA.length" class="list-col">
						<div v-for="(item,ind) in listA" :key="ind" @click="tabBtn(item,ind)">
							<div>{{ item.name }}</div>
						</div>
					</div>
				</div>
				<div @mouseenter="handleMouseOver('企业文化')" class="cont_bj1"><router-link to="/Qywenhau" class="cont_one"
						:class="[indexActive == 5?'':'']" @click="jump(index)">企业文化</router-link>
					<div v-show="text == '企业文化' && show && listA.length" class="list-col">
						<div v-for="(item,ind) in listA" :key="ind" @click="tabBtn(item,ind)">
							<div>{{ item.name }}</div>
						</div>
					</div>
				</div>
				<div @mouseenter="handleMouseOver('联系我们')" class="cont_bj1"><router-link to="/lianxiwm" class="cont_one"
						:class="[indexActive == 6?'':'']" @click="jump(index)">联系我们</router-link>
					<!-- <div v-show="text == '联系我们' && show && listA.length" class="list-col">
					<div v-for="(item,ind) in listA" :key="ind" @click="tabBtn(item,ind)">
						<div>{{ item.name }}</div>
					</div>
				</div> -->
				</div>
			</div>
		</div>
		<!-- <div v-if="show"
      @mouseleave="doSomething($event)"
      style="cursor: pointer;width: 1rem;background: rgba(0, 0, 0, 0.6);position: absolute;z-index: 99999;margin-top: .0rem;display: flex;flex-direction: column;align-items: center;">
            <div style="display: flex;flex-direction: column;">
                <div v-for="(item,ind) in listA" class="XXX" style="display: flex;flex-direction: row;" :key="ind" @click="tabBtn(item,ind)">
                    <div class="textXAA">{{item.name}}</div>
                </div>
            </div>
        </div> -->
	</div>
</template>

<script>
	import axios from 'axios'
	export default {
		name: 'HelloWorld',
		data() {
			return {
				indexActive: 0,
				search: "",
				listA: [],
				show: false,
				indexA: 0,
				text: ""
			}
		},
		created() {

		},
		methods: {
			gettabs() {
				this.indexA = 2
				axios({
					method: 'post',
					url: process.env.VUE_APP_ASSET_ADMIN_HOST + '/api/New_type_list/qyjjTypeList',
				}).then(res => {
					this.listA = []
					let arrr = res.data.data
					arrr.forEach(item => {
						this.listA.push(item)
					});
					// this.listA.pop()
				})
			},
			tabBtn(item, index) {
				this.indexA = index
				if (this.text == '企业简介') {
					item.indexaAA = index
					this.$emit("btnSele", item)
					this.$router.push({
						path: '/briefIntroduction',
						query: {
							id: this.indexA,
						}
					})
				} else if (this.text == '新闻动态') {
					item.indexaAA = index
					this.$emit("btnSele", item)
					this.$router.push({
						path: '/newDongtai',
						query: {
							index: this.indexA,
						}
					})
				} else if (this.text == '国内项目') {
					item.indexaAA = index
					this.$emit("btnSele", item)
					this.$router.push({
						path: '/DomesticProjects',
						query: {
							index: this.indexA,
						}
					})
				} else if (this.text == '海外项目') {
					item.indexaAA = index
					this.$emit("btnSele", item)
					this.$router.push({
						path: '/OverseasProjects',
						query: {
							index: this.indexA,
						}
					})
				} else if (this.text == '党建工作') {
					item.indexaAA = index
					this.$emit("btnSele", item)
					this.$router.push({
						path: '/dangjian',
						query: {
							index: this.indexA,
						}
					})
				} else if (this.text == '企业文化') {
					item.indexaAA = index
					this.$emit("btnSele", item)
					this.$router.push({
						path: '/Qywenhau',
						query: {
							index: this.indexA,
						}
					})
				}
			},
			doSomething(e) {
				this.show = false
				this.listA = []
			},
			handleMouseOver(e) {
				this.listA = []
				// let text = e.target.innerText
				// this.text = e.target.innerText
				let text = e
				this.text = e
				if (text == '首页') {
					this.gettabs()
					this.show = false
				} else if (text == '企业简介') {
					this.show = true
					this.gettabs()
				} else if (text == '新闻动态') {
					this.show = true
					axios({
						method: 'get',
						url: process.env.VUE_APP_ASSET_ADMIN_HOST + '/api/New_type_list/xwdtTypeList',
					}).then(res => {
						this.listA = []
						let arrr = res.data.data
						arrr.forEach(item => {
							this.listA.push(item)
						});
						// this.listA.pop()
					})
				} else if (text == '国内项目') {
					this.show = true
					axios({
						method: 'get',
						url: process.env.VUE_APP_ASSET_ADMIN_HOST + '/api/New_type_list/gnxmTypeList',
					}).then(res => {
						this.listA = []
						let arrr = res.data.data
						arrr.forEach(item => {
							this.listA.push(item)
						});
						// this.listA.pop()
					})
				} else if (text == '海外项目') {
					this.show = true
					axios({
						method: 'get',
						url: process.env.VUE_APP_ASSET_ADMIN_HOST + '/api/New_type_list/hwxmTypeList',
					}).then(res => {
						this.listA = []
						let arrr = res.data.data
						arrr.forEach(item => {
							this.listA.push(item)
						});
						// this.listA.pop()
					})
				} else if (text == '党建工作') {
					this.show = true
					axios({
						method: 'get',
						url: process.env.VUE_APP_ASSET_ADMIN_HOST + '/api/New_type_list/djgzTypeList',
					}).then(res => {
						this.listA = []
						let arrr = res.data.data
						arrr.forEach(item => {
							this.listA.push(item)
						});
						// this.listA.pop()
					})
				} else if (text == '企业文化') {
					this.show = true
					axios({
						method: 'get',
						url: process.env.VUE_APP_ASSET_ADMIN_HOST + '/api/New_type_list/qywhTypeList',
					}).then(res => {
						this.listA = []
						let arrr = res.data.data
						arrr.forEach(item => {
							this.listA.push(item)
						});
						// this.listA.pop()
					})
				}
			},
			jump(index) {
				this.indexActive = index
			},
			sousuo() {
				console.log(this.search, "搜索")
				this.$emit("sousuo", this.search)
			},
			homeBtn() {
				this.$router.push({
					path: '/index',
				})
			},
		}
	}
</script>

<style scoped>
	a {
		text-decoration: none;
		color: #FFFFFF;
		font-size: .1rem;
	}

	.router-link-active {
		background: #003973 !important;
		font-size: .1rem;
		/* line-height: .3125rem; */
	}

	.cont_bj1 {
		height: .3125rem;
		display: flex;
		position: relative;
	}

	.cont_bj {
		/* width: 65%; */
		height: 100%;
		display: flex;
		flex-direction: row;
		color: #fff;
		position: relative;
		justify-content: space-between;
	}

	.cont_one {
		/* width: .7552rem;
    height: .3125rem; */
		text-align: center;
		line-height: .3125rem;
		padding: 0 30px;
		/* margin-left: .2083rem; */
	}

	.cont_one:hover {
		background: #003973 !important;
		font-size: .1rem;
	}

	.header {
		width: 100%;
		height: .7031rem;
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-content: center;
		background-image: url("../image/background.png");
		background-size: 100% 100%;
	}

	.header_cont {
		width: 65%;
		height: 100%;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-content: center;
	}

	.input {
		width: 1.5rem;
		height: .2344rem;
		background: #FFFFFF;
		border-radius: .1198rem;
	}

	.inputLeft {
		width: 80%;
		height: 95%;
		background: #FFFFFF;
		border-radius: .1198rem 0px 0px .1198rem;
		padding: 0px;
		margin: 0px;
		border: solid 0px #000;
		text-indent: .1563rem;
		color: #09396F;
	}

	.divInput {
		width: 20%;
		height: 99%;
		background: #09396F;
		border-radius: 0px .1198rem .1198rem 0px;
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
	}

	.textXAA {
		color: #D6D6D6;
		font-size: .0833rem;
	}

	.XXX :hover {
		color: #FFF !important;
	}

	.list-col {
		position: absolute;
		top: .3rem;
		left: 50%;
		transform: translateX(-50%);
		text-align: center;
		/* width: .5rem; */
		background: #FFF;
		padding: 10px 0;
		border-radius: 10px;
		line-height: .3rem;
		color: #333;
		font-size: 18px;
	}

	.list-col>div {
		padding: 0 20px;
		word-break: keep-all;
	}

	.list-col>div:hover {
		background: rgb(38, 122, 203);
		color: #FFF;
	}
</style>