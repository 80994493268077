<template>
    <div>
        <headerH @btnSele="btnClick($event)"></headerH>
        <!--<div style="width: 100%;height: .4375rem;background: rgba(0, 0, 0, 0.6);position: fixed;z-index: 99999;margin-top: 1.015rem;display: flex;flex-direction: row;align-items: center;">
            <div style="display: flex;flex-direction: row;margin-left: 2.524rem;">
                <div v-for="(item,ind) in listA" style="display: flex;flex-direction: row;" :key="ind" @click="tabBtn(item,ind)">
                    <div class="textX" :class="indexA == ind ?'aaa':''" style="margin-left: .1979rem;" @click="indexA = 0">{{item.name}}</div>
                    <div style="width: .0052rem;height: .125rem;background: #FFFFFF;margin-left: .1979rem;"></div>
                </div>
            </div>
        </div>-->
        <div style="width: 100%;display: flex;flex-direction: column;justify-content: center;align-items: center;">
          <div style="width: 65%;height: auto;flex-direction: column;margin-top: 1.015rem;">
            <el-carousel :interval="5000" arrow="always">
              <el-carousel-item v-for="(item,ind) in imgData" :key="ind"  >
                <img :src="item.image" style="width: 100%;" alt="">
              </el-carousel-item>
            </el-carousel>
            <div style="width: 100%;display: flex;flex-direction: row;margin-top: .2604rem;">
                <div style="width: 1.0938rem;height: 1.0938rem;display: flex;flex-direction: column;">
                    <div v-for="(item,index) in listA" :key="index"
                    @click="seleBtn(item,index)"
                    :class="indexA == index ?'acacac':''" style="width: 99%;height: .3594rem;text-align: center;line-height: .3594rem;text-align: center;display: flex;flex-direction: row;align-items: center;justify-content: center;color: #003973;border: 1px solid #E2E2E2;">
                        <div style="width: .03rem;height: .03rem;background: #CACACA;margin-right: .0521rem; border-radius: 50%;"></div>
                        <div style="font-size: .09rem;margin-left: .0521rem;">{{item.name}}</div>
                    </div>
                </div>
                <div style="width: 5rem;display: flex;flex-direction: column;margin-left: .1563rem;">
                    <div style="display: flex;flex-direction: row;align-items: center;">
                        <div style="width: .0156rem;height: .0729rem;background: #003973;margin-top: .0156rem;"></div>
                        <div style="font-size: .0833rem;margin-left: .0521rem;display: flex;flex-direction: row;">
                            <div>当前位置：企业简介 ></div>
                            <div style="color: #003973;">{{listA[indexA].name}}</div>
                        </div>
                    </div>
                    <div style="width: 5rem;height: .0208rem;background: #003973;margin-top: .1042rem;"></div>
                    <div style="margin-top: .1563rem;width: 5rem;height: auto;padding-bottom: .5208rem;">
                        <div v-html="infoData.info_content" v-if="!imageList.length">
                        </div>
                        <div v-if="imageList.length" class="image-list">
                            <div v-for="(item, index) in imageList" :key="index">
                                <el-image :src="item" fit="contain" :z-index="999999" :preview-src-list="imageList"></el-image>
                            </div>
                        </div>
                    </div>
                    <!-- <div style="width: 5rem;height: ;" v-if="listA[indexA].name == '发展历程'">
                        <div style="display: flex;flex-direction: row;">
                            <div style="width: .5104rem;height: ;display: flex;flex-direction: column;align-items: center;margin-top: .2083rem;position: relative;">
                                <div style="width: .5104rem;height: .2083rem;background: #003973;text-align: center;line-height: .2083rem;color: #fff" :style="{marginTop:index == 0?'':'1.4323rem'}" v-for="(item,index) in dataListy" :key="index">{{item.time}}</div>
                                <div style="width: .5104rem;height: .2083rem;background: #003973;text-align: center;line-height: .2083rem;color: #fff;margin-top: 1.4323rem;">2017</div>
                                <div style="width: .5104rem;height: .2083rem;background: #003973;text-align: center;line-height: .2083rem;color: #fff;margin-top: 1.4323rem;">2012</div>
                                <div style="width: .5104rem;height: .2083rem;background: #003973;text-align: center;line-height: .2083rem;color: #fff;margin-top: 1.4323rem;">2001</div>
                                <div style="width: .5104rem;height: .2083rem;background: #003973;text-align: center;line-height: .2083rem;color: #fff;margin-top: 1.4323rem;">1995</div>
                                <div style="width: .5104rem;height: .2083rem;background: #003973;text-align: center;line-height: .2083rem;color: #fff;margin-top: 1.4323rem;">1985</div>
                                <div style="width: .5104rem;height: .2083rem;background: #003973;text-align: center;line-height: .2083rem;color: #fff;margin-top: 1.4323rem;">1972</div>
                                <div style="background:  #003973;width: .0052rem;top: .0573rem;position: absolute;z-index: -999;" :style="{ height:1.63 * (dataListy.length-1) + 'rem' }">
                                </div>
                            </div>
                            <div style="width: 4.3333rem;height: ;margin-left: .1563rem;display: flex;flex-direction: column;">
                                <div style="width: 100%;height: 1.4323rem;background: #F5F5F5;display: flex;flex-direction: column;justify-content: center;margin-top: .2083rem;" v-for="(item,index) in dataListy" :key="index">
                                    <div style="display: flex;flex-direction: row;align-items: center;margin-left: .1563rem;">
                                        <div style="width: .0156rem;height: .0729rem;background-color: #003973;margin-top: .0156rem;"></div>
                                        <div style="font-size: .0833rem;color: #003973;margin-left: .0542rem;font-weight: 600;">{{item.name}}</div>
                                    </div>
                                    <div style="display: flex;flex-direction: row;justify-content: center;width: 100%;margin-top: .1042rem;">
                                        <div style="width: 4.0208rem;height: .0052rem;background: #D7D7D7;"></div>
                                    </div>
                                    <div style="width: 4.0208rem;height: .8958rem;display: flex;flex-direction: row;margin-left: .1563rem;margin-top: .1042rem;">
                                        <div style="width: 2.4323rem;height: 100%;font-size: .0938rem;color: #666;" v-html="item.info_content"></div>
                                        <img :src="item.image" style="width: 1.4323rem;height: .8958rem;margin-left: .1563rem;" alt="">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> -->
                </div>
            </div>
          </div>
        </div>
        <bottomB></bottomB>
    </div>
  </template>
  
  <script>
  import headerH from '../components/HelloWorld.vue'
  import bottomB from '../components/bottom.vue'
  import axios from 'axios'
  import Vue from 'vue'
  export default {
    data () {
      return {
        indexA:0,
        listA:[{name:''},{name:''}],
        infoData:{},
        imgData:[],
        id:0,
        dataListy:[],
		btnId: 0,
		imageList: [],
      }
    },
    components: {
        headerH,
        bottomB,
    },
    created(){
        // this.id = this.$route.query.id == 2?1:0
        this.id = this.$route.query.id || 0
        this.indexA = this.id
        this.gettabs()
        this.lunbo()
    },
    methods:{
        formatRichText(html) { //控制小程序中图片大小
			let newContent = ''
			if (typeof(html) == 'string') { //检测值为字符串才能进行replace操作
				newContent = html.replace(/<p[^>]*>/gi,
				function(match) {
					// match = match.replace(/style=""/gi, '').replace(/style=''/gi, '');
					// match = match.replace(/style="[^"]+"/gi, '').replace(/style='[^']+'/gi, '');
					// match = match.replace(/width="[^"]+"/gi, '').replace(/width='[^']+'/gi, '');
					// match = match.replace(/height="[^"]+"/gi, '').replace(/height='[^']+'/gi, '');
					return match;
				});
				newContent = newContent.replace(/style="[^"]+"/gi,
				function(match) {
					match = match.replace(/width:[^;]+;/gi, 'max-width:100%;').replace(/width:[^;]+;/gi, 'max-width:100%;');
					return match;
				});
				newContent = newContent.replace(/<br[^>]*\/>/gi, '');
                newContent = newContent.replace(/\<img/gi, '<img style="max-width:100%;height:auto;display:inline-block;margin:10rpx auto;"');
				// newContent = newContent.replace(/\<p/gi, '<p style="max-width:100%;padding: 0px; margin-top: 0px;margin-bottom: 0px;font-size: 14px;color: rgb(51, 51, 51);font-family: &quot;Microsoft YaHei&quot;;white-space: normal;line-height: 3em;text-indent: 2.5em;"');
				// newContent = newContent.replace(/\<p style="/gi, '<p style="max-width:100%;padding: 0px; margin-top: 0px;margin-bottom: 0px;font-size: 14px;color: rgb(51, 51, 51);font-family: &quot;Microsoft YaHei&quot;;white-space: normal;text-indent: 2.5em;"');
				return newContent;
			}
		},
        btnClick(e){
            // this.indexA = e.indexaAA == 2?1:0
            this.indexA = e.indexaAA
            axios({
                method: 'post',
                url: `http://www.sxsyjsjy.com/api/New_list/qyjjList?type_id=${e.id}`,
            }).then(res => {
                this.imageList = []
                if (e.id == 9) {
                    res.data.data.data.map((val) => {
                        this.imageList.push(val.image)
                    })
                    return
                }
                this.infoData = res.data.data.data[0]
                this.infoData.info_content = this.formatRichText(this.infoData.info_content)
            })
        },
        lunbo(){
            axios({
                method: 'post',
                url: process.env.VUE_APP_ASSET_ADMIN_HOST + '/api/index/banner?type=index',
            }).then(res => {
                this.imgData = res.data.data
            })
        },
        seleBtn(item,index){
			this.btnId = item.id
            this.indexA = index
            axios({
                method: 'post',
                url: `http://www.sxsyjsjy.com/api/New_list/qyjjList?type_id=${item.id}`,
            }).then(res => {
                this.imageList = []
                if (item.id == 9) {
                    res.data.data.data.map((val) => {
                        this.imageList.push(val.image)
                    })
                    return
                }
                this.infoData = res.data.data.data[0]
                this.infoData.info_content = this.formatRichText(this.infoData.info_content)
                this.dataListy = res.data.data.data
            })
        },
        gettabs(){
            axios({
                method: 'post',
                url: process.env.VUE_APP_ASSET_ADMIN_HOST + '/api/New_type_list/qyjjTypeList',
            }).then(res => {
                this.listA = res.data.data
                axios({
                    method: 'post',
                    url: `http://www.sxsyjsjy.com/api/New_list/qyjjList?type_id=${this.listA[this.id].id}`,
                }).then(res => {
                    this.imageList = []
                    if (this.listA[this.id].id == 9) {
                        res.data.data.data.map((val) => {
                        this.imageList.push(val.image)
                    })
                    console.log(this.imageList)
                    return
                }
                    this.infoData = res.data.data.data[0]
                    this.infoData.info_content = this.formatRichText(this.infoData.info_content)
                    this.dataListy = res.data.data.data
                })
            })
        },
    }
  }
  </script>
  
  <style lang="scss">
        .acacac{
            background: #003973;
            color: #fff !important;
        }
        // .el-carousel__container{
        //     height: 2.3438rem !important;
        // }
        .textX{
            color: #D6D6D6;
            font-size: .0833rem;
        }
        .aaa{
            color: #fff;
        }
        .image-list {
            width: 100%;
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;

            .el-image {
                width: 100%;
                height: 100%;
            }
            // :nth-child(1),  :nth-child(2){
            > div {
                width: 400px;
                height: 400px;
                margin-top: 20px;
            }
        }
  </style>
  