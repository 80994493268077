import Vue from 'vue'
import Router from 'vue-router'
// import HelloWorld from '@/components/HelloWorld'
import index from '../views/index.vue'
import briefIntroduction from '../views/briefIntroduction.vue'
import newDongtai from '../views/newDongtai.vue'
import dangjian from '../views/dangjian.vue'
import DomesticProjects from '../views/DomesticProjects.vue'
import OverseasProjects from '../views/OverseasProjects.vue'
import Qywenhau from '../views/Qywenhau.vue'
import lianxiwm from '../views/lianxiwm.vue'
import sousuo from '../views/sousuo.vue'

Vue.use(Router)

export default new Router({
  routes: [
    {
      path: '/',
      name: 'index',
      component: index
    },
    {
      path: '/index',
      name: 'index',
      component: index
    },
    {
      path: '/briefIntroduction',
      name: 'briefIntroduction',
      component: briefIntroduction
    },
    {
      path: '/newDongtai',
      name: 'newDongtai',
      component: newDongtai
    },
	{
		path: '/dangjian',
		name: 'dangjian',
		component: dangjian
	},
    {
      path: '/DomesticProjects',
      name: 'DomesticProjects',
      component: DomesticProjects
    },
    {
      path: '/OverseasProjects',
      name: 'OverseasProjects',
      component: OverseasProjects
    },
    {
      path: '/Qywenhau',
      name: 'Qywenhau',
      component: Qywenhau
    },
    {
      path: '/lianxiwm',
      name: 'lianxiwm',
      component: lianxiwm
    },
    {
      path: '/sousuo',
      name: 'sousuo',
      component: sousuo
    }
  ]
})
