<template>
  <div class="imagelist" @mouseenter="hover" @mouseleave="run">
    <div class="btn btn-left" :class="isHover ? 'opacity' : ''" @click="btnPre">
      <i class="el-icon-arrow-left"></i>
    </div>
    <div class="box" :style="{ left: left + 'px' }" :class="isTrans ? 'transition' : ''">
      <div class="image-box" v-for="(item, index) in listDatas" :key="index">
        <el-image :src="item" :z-index="999999" :preview-src-list="listDatas"></el-image>
      </div>
    </div>
    <div class="btn btn-right" :class="isHover ? 'opacity' : ''" @click="btnNext">
      <i class="el-icon-arrow-right"></i>
    </div>
  </div>
</template>

<script>
export default {
  name: 'imageList',
  props: {
    listData: {}
  },
  data() {
    return {
      left: 0,
      listDatas: [...this.listData, ...this.listData],
      isTrans: false,
      isHover: false
    }
  },
  mounted() {
    if (this.listData.length < 5) {
      this.listDatas = [...this.listDatas, ...this.listData]
    }
    clearInterval(this.timer)
    console.log(this.listData, '11111111')
    this.run()
  },
  methods: {
    btnPre() {
      this.left += 270
      if (this.left >= 0 ) {
        this.left = -this.listData.length * 270
      }
    },
    btnNext() {
      this.left -= 270
      if (this.left <= -this.listData.length * 270) {
        this.left = 0
      }
    },
    hover() {
      clearInterval(this.timer)
      this.isHover = true
    },
    run() {
      clearInterval(this.timer)
      let boxWidth = this.listData.length * 270
      this.isHover = false
      this.timer = setInterval(() => {
        if (this.left <= -boxWidth) {
          // this.isTrans = false
          this.left = 0
          // setTimeout(() => {
          //   this.isTrans = true
          //   this.left -= 270
          // }, 10);
        } else {
          this.left -= 1
        }
      }, 10);
    },
  },
}
</script>

<style lang="scss" scoped>
.imagelist {
  width: 100%;
  overflow: hidden;
  position: relative;
}

.transition {
  transition: all 1.5s linear;
}

.box {
  display: flex;
  width: 10000px;
  overflow: hidden;
  position: relative;

  .image-box {
    width: 250px;
    height: 150px;
    margin: 0 10px;

    .el-image {
      width: 100%;
      height: 100%;
    }
  }
}
.btn {
  position: absolute;
  top: 50%;
  z-index: 9;
  width: 50px;
  height: 50px;
  background: rgba(255, 255, 255, 0.8);
  border-radius: 50%;
  line-height: 50px;
  text-align: center;
  transform: translateY(-50%);
  opacity: 0;
  transition: all .5s ease;
}
.opacity {
  opacity: 1;
}
.btn-left {
  left: 10px;
}
.btn-right {
  right: 10px;
}
</style>