<template>
    <div>
        <headerH @btnSele="btnClick($event)"></headerH>
        <!--<div style="width: 100%;height: .4375rem;background: rgba(0, 0, 0, 0.6);position: fixed;z-index: 99999;margin-top: 1.015rem;display: flex;flex-direction: row;align-items: center;">
            <div style="display: flex;flex-direction: row;margin-left: 2.524rem;">
                <div v-for="(item,ind) in listA" style="display: flex;flex-direction: row;" :key="ind" @click="tabBtn(item,ind)">
                    <div class="textX" :class="indexA == ind ?'aaa':''" style="margin-left: .1979rem;" @click="indexA = 0">{{item.name}}</div>
                    <div style="width: .0052rem;height: .125rem;background: #FFFFFF;margin-left: .1979rem;"></div>
                </div>
            </div>
        </div>-->
        <div style="width: 100%;display: flex;flex-direction: column;justify-content: center;align-items: center;">
          <div style="width: 65%;height: auto;flex-direction: column;margin-top: 1.015rem;">
            <el-carousel :interval="5000" arrow="always">
              <el-carousel-item v-for="(item,ind) in imgData" :key="ind"  >
                <img :src="item.image" style="width: 100%;" alt="">
              </el-carousel-item>
            </el-carousel>
            <div style="width: 100%;display: flex;flex-direction: row;margin-top: .2604rem;">
                <div style="width: 1.0938rem;display: flex;flex-direction: column;">
                    <div v-for="(item,index) in listA" :key="index"
                    @click="seleBtn(item,index)"
                    :class="indexA == index ?'acacac':''" style="width: 99%;height: .3594rem;text-align: center;line-height: .3594rem;text-align: center;display: flex;flex-direction: row;align-items: center;justify-content: center;color: #003973;border: 1px solid #E2E2E2;">
                        <!-- <div style="width: .0625rem;height: .0104rem;background: #CACACA;margin-right: .0521rem;"></div> -->
						<div style="width: .03rem;height: .03rem;background: #CACACA;margin-right: .0521rem; border-radius: 50%;"></div>
                        <div style="font-size: .09rem;margin-left: .0521rem;">{{item.name}}</div>
                    </div>
                </div>
                <div style="width: 5rem;display: flex;flex-direction: column;margin-left: .1563rem;">
                    <div style="display: flex;flex-direction: row;align-items: center;">
                        <div style="width: .0156rem;height: .0729rem;background: #003973;margin-top: .0156rem;"></div>
                        <div style="font-size: .0833rem;margin-left: .0521rem;display: flex;flex-direction: row;">
                            <div>当前位置：党建工作 > </div>
                            <div @click="detailsShow = false" style="cursor: pointer;color: #003973;"> {{listA[indexA].name}}</div>
                            <div v-if="detailsShow == true"> > 详情</div>
                        </div>
                    </div>
                    <div style="width: 5rem;height: .0208rem;background: #003973;margin-top: .1042rem;"></div>
                    <div style="width: 5rem;height: auto;padding-bottom: .3208rem;">
                      <!--<div v-html="infoData.info_content"></div>-->
                      <div style="display: flex;flex-direction: column;background: #FBFBFB;" v-for="(itemA,indexA) in dataList" :key="indexA" v-if="detailsShow == false">
                        <div class="rigthC" style="display: flex;flex-direction: column;margin-top: .07rem;cursor: pointer;margin-left: .1063rem;padding-bottom: 0.1rem;padding-right: 0.1rem;" 
                        @click="details(item)"
                        v-for="(item,index) in itemA" :key="index">
                            <div style="display: flex;flex-direction: row;align-items: center;justify-content: space-between;">
                                <div style="display: flex;flex-direction: row;justify-content: center;align-items: center;">
                                    <div style="width: .0313rem;height: .0313rem;background: #003973;border-radius: 100%;margin-top: .0221rem;"></div>
                                    <!-- <div v-html="item.info_content" style="margin-left: .0417rem;font-size: .0833rem;width: 4.1042rem;-webkit-line-clamp: 1;display: -webkit-box;-webkit-box-orient: vertical;overflow: hidden;text-overflow: ellipsis" ></div> -->
                                    <div style="margin-left: .0417rem;font-size: .08rem;width: 4.1042rem;-webkit-line-clamp: 1;display: -webkit-box;-webkit-box-orient: vertical;overflow: hidden;text-overflow: ellipsis" >{{ item.name }}</div>
                            </div>
                            <div style="font-size: .0833rem;color: #9F9F9F;">{{item.time}}</div>
                            </div>
                        </div>
                        <!-- <div  v-if="dataList.length > 1" style="width: 100%;margin-top: 30px;border: #979797 dashed 1px;"></div> -->
                      </div>
                      <div v-if="detailsShow == true">
                        <div style="display: flex;flex-direction: column;margin-top: .3125rem;">
                            <div style="width: 100%;display: flex;flex-direction: row;justify-content: center;font-size: .1042rem;color: #1E1E1E;">{{detaInfo.name}}</div>
                            <div style="width: 100%;display: flex;flex-direction: row;justify-content: center;margin-top: .1563rem;">
                                <div style="width: 75%;display: flex;flex-direction: row;justify-content: space-around;color: #969696;font-size: .0833rem;">
                                    <div>作者：{{detaInfo.author}}</div>
                                    <div>时间：{{timestampToTime(detaInfo.time)}}</div>
                                    <div>浏览量：{{detaInfo.page_view}}</div>
                                </div>
                            </div>
                            <div style="margin-top: .1563rem;" v-html="detaInfo.info_content"></div>
                        </div>
                      </div>
                    </div>
                    <div style="width: 100%;display: flex;flex-direction: row;justify-content: center;align-items: center;" v-if="detailsShow == false">
                        <el-pagination
                            background
                            prev-text="上一页"
                            next-text="下一页"
                            layout="prev, pager, next"
                            :page-size="page.pagesize"
                            :current-page="page.page"
                            @size-change="handleSizeChange"
                            @current-change="handleCurrentChange"
                            :total="page.total">
                        </el-pagination>
                    </div>
                </div>
            </div>
          </div>
        </div>
        <bottomB></bottomB>
    </div>
  </template>
  
  <script>
  import headerH from '../components/HelloWorld.vue'
  import bottomB from '../components/bottom.vue'
  import axios from 'axios'
  export default {
    data () {
      return {
        indexA:0,
        listA:[{name:''},{name:''}],
        infoData:{},
        imgData:[],
        dataList:[],
        page:{
            page: 1, // 默认页码
            pagesize: 10, // 每页条数
            total: 100
        },
        detailsShow:false,
        detaInfo:{},
        id:''
      }
    },
    components: {
        headerH,
        bottomB,
    },
    created(){
        if(this.$route.query.id){
            this.indexA = this.$route.query.id
            this.detailsShow = true
            axios({
                method: 'post',
                url: `http://www.sxsyjsjy.com/api/New_info/xwdtInfo?id=${this.$route.query.getID}`,
            }).then(res => {
                this.detaInfo = res.data.data.info
            })
        }
        if(this.$route.query.idS){
            if(this.$route.query.idS == 5){this.indexA = 0}
            if(this.$route.query.idS != 5){this.indexA = 1}
            this.detailsShow = true
            axios({
                method: 'post',
                url: `http://www.sxsyjsjy.com/api/New_info/xwdtInfo?id=${this.$route.query.getID}`,
            }).then(res => {
                this.detaInfo = res.data.data.info
            })
        }
        if(this.$route.query.index){
            this.indexA = this.$route.query.index == 2?1:0
        }
        this.gettabs()
        this.lunbo()
    },
    methods:{
        btnClick(e){
            // this.indexA = e.indexaAA == 2?1:0
			this.indexA = e.indexaAA
            axios({
                method: 'post',
                url: `http://www.sxsyjsjy.com/api/New_list/xwdtList?type_id=${this.listA[this.indexA].id}&page=${this.page.page}&limit=${this.page.pagesize}`,
            }).then(res => {
                this.infoData = res.data.data.data[0]
                let arr = res.data.data.data
                arr.forEach(item => {
                    item.time = this.timestampToTime(item.time)
                })
                this.dataList = this.getArr(arr,5)
                this.page.total = res.data.data.total
            })
        },
        timestampToTime(timestamp) {
            // 时间戳为10位需*1000，时间戳为13位不需乘1000
            var date = new Date(timestamp * 1000);
            var Y = date.getFullYear() + "-";
            var M =
            (date.getMonth() + 1 < 10
                ? "0" + (date.getMonth() + 1)
                : date.getMonth() + 1) + "-";
            var D = (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + " ";
            var h = date.getHours() + ":";
            var m = date.getMinutes() + ":";
            var s = date.getSeconds();
            return Y + M + D ;
        },
        handleSizeChangeAAA(page){
            console.log("上一页")
        },
        handleCurrentChangeAAA(page){
            console.log("下一页")
        },
        //详情
        details(item){
            this.detailsShow = true
            axios({
                method: 'post',
                url: `http://www.sxsyjsjy.com/api/New_info/djgzInfo?id=${item.id}`,
            }).then(res => {
                this.detaInfo = res.data.data.info
                this.detaInfo.info_content = this.formatRichText(this.detaInfo.info_content)
            })
        },
        formatRichText(html) { //控制小程序中图片大小
            let newContent = ''
            if (typeof(html) == 'string') { //检测值为字符串才能进行replace操作
                newContent = html.replace(/<img[^>]*>/gi,
                function(match) {
                    match = match.replace(/style=""/gi, '').replace(/style=''/gi, '');
                    match = match.replace(/style="[^"]+"/gi, '').replace(/style='[^']+'/gi, '');
                    match = match.replace(/width="[^"]+"/gi, '').replace(/width='[^']+'/gi, '');
                    match = match.replace(/height="[^"]+"/gi, '').replace(/height='[^']+'/gi, '');
                    return match;
                });
                newContent = newContent.replace(/style="[^"]+"/gi,
                function(match) {
                    match = match.replace(/width:[^;]+;/gi, 'max-width:100%;').replace(/width:[^;]+;/gi, 'max-width:100%;');
                    return match;
                });
                newContent = newContent.replace(/<br[^>]*\/>/gi, '');
                newContent = newContent.replace(/\<img/gi, '<img style="max-width:100%;height:auto;display:inline-block;margin:10rpx auto;"');
                return newContent;
            }
        },
        // 页容量改变
        handleSizeChange(size) {
            this.page.pagesize = size
            // 页容量回到第一页
            this.page.page = 1
            this.gettabs()
        },
        // 页码改变
        handleCurrentChange(page) {
            this.page.page = page
            this.gettabs()
        },
        lunbo(){
            axios({
                method: 'post',
                url: process.env.VUE_APP_ASSET_ADMIN_HOST + '/api/index/banner?type=djgz',
            }).then(res => {
                this.imgData = res.data.data
            })
        },
        getArr(arr,num){
            let newArr=[...arr]  // 因为splice会改变原数组，要深拷贝一下
            let list =[]
            for (let i = 0; i < newArr.length;) {
                list.push(newArr.splice(i,num))  
            }
            return list
        },
        seleBtn(item,index){
            this.detailsShow = false
            this.page.page = 1
            this.indexA = index
            axios({
                method: 'post',
                url: `http://www.sxsyjsjy.com/api/New_list/xwdtList?type_id=${item.id}&page=${this.page.page}&limit=${this.page.pagesize}`,
            }).then(res => {
                this.infoData = res.data.data.data[0]
                let arr = res.data.data.data
                arr.forEach(item => {
                    item.time = this.timestampToTime(item.time)
                })
                this.dataList = this.getArr(arr,5)
                this.page.total = res.data.data.total
            })
        },
        gettabs(){
            axios({
                method: 'post',
                url: process.env.VUE_APP_ASSET_ADMIN_HOST + '/api/New_type_list/djgzTypeList',
            }).then(res => {
                this.listA = res.data.data
                axios({
                    method: 'post',
                    url: `http://www.sxsyjsjy.com/api/New_list/djgzList?type_id=${this.listA[this.indexA].id}&page=${this.page.page}&limit=${this.page.pagesize}`,
                }).then(res => {
                    this.infoData = res.data.data.data[0]
                    let arr = res.data.data.data
                    arr.forEach(item => {
                        item.time = this.timestampToTime(item.time)
                    })
                    this.dataList = this.getArr(arr,5)
                    this.page.total = res.data.data.total
                })
            })
        },
        tabBtn(item,index){
            this.detailsShow = false

            this.page.page = 1
            this.indexA = index
            axios({
                method: 'post',
                url: `http://www.sxsyjsjy.com/api/New_list/xwdtList?type_id=${item.id}&page=${this.page.page}&limit=${this.page.pagesize}`,
            }).then(res => {
                this.infoData = res.data.data.data[0]
                let arr = res.data.data.data
                arr.forEach(item => {
                    item.time = this.timestampToTime(item.time)
                })
                this.dataList = this.getArr(arr,5)
                this.page.total = res.data.data.total
            })
        },
    }
  }
  </script>
  
  <style lang="scss">
        .rigthC :hover{
            color: #003973 !important;
        }
        .el-pagination{
            padding: 0px 0px;
        }
        .btn-prev,.btn-next{
          
            padding: 1px 10px !important;
        }
        .acacac{
            background: #003973;
            color: #fff !important;
        }
        // .el-carousel__container{
        //     height: 2.3438rem !important;
        // }
        .textX{
            color: #D6D6D6;
            font-size: .0833rem;
        }
        .aaa{
            color: #fff;
        }
        .el-pagination.is-background .el-pager li:not(.disabled).active{
            background-color: #003973 !important;
        }
  </style>
  